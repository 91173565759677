import React, { useState, useRef, useEffect, useCallback, useMemo } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import IconButton from '@mui/material/IconButton';
import InputAdornment from "@mui/material/InputAdornment";
import { styled, alpha } from "@mui/material/styles";

import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";

import SearchIcon from "@mui/icons-material/Search";
import CityIcon from "@mui/icons-material/LocationCity"; // 'Apartment'; //LocationOn'; //'MapsHomeWork'; //'LocationCity'
import PortIcon from "@mui/icons-material/Anchor";
import DiveIcon from "@mui/icons-material/ScubaDiving";
import LatLonIcon from "@mui/icons-material/Place";
// import LoadingIcon from '@mui/icons-material/MoreHoriz'; //MoreHoriz'; // Troubleshoot
//import LocationOnIcon from '@mui/icons-material/LocationOn';


// ------------- position and styles -----------------

const StyledAutoComplete = styled(Autocomplete)(({ theme }) => ({
  color: "inherit",
  width: "60ch",
  paddingTop: "10px", paddingBottom: "10px", paddingLeft: "10px", paddingRight: "10px",

  "& .MuiOutlinedInput-root .MuiAutocomplete-input": {
    paddingTop: "0px", paddingBottom: "0px", paddingLeft: "0px", paddingRight: "0px",
    margin: 0,
  },
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  color: "inherit",
  borderRadius: theme.shape.borderRadius,
  paddingTop: "0px", paddingBottom: "0px",
  backgroundColor: alpha(theme.palette.common.white, 0.5),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.85),
  },
  "& .MuiFormLabel-root": {
    justifyContent: "center",
  },
}));

// ====================== Component =====================

function AutoCompleteSite({ options, inputValue, handleOnInputChange, handleOnChange, handleOnValidate, handleIsOptionEqualToValue }) {
  const [open, setOpen] = useState(false);
  const autocompleteRef = useRef(null);

  const getIcon = (site_type) => {
    switch (site_type) {
      case "city_gmap":
        return <CityIcon style={{ color: "grey" }} />;
      case "port":
        return <PortIcon style={{ color: "green" }} />;
      case "dive_site":
        return <DiveIcon style={{ color: "blue" }} />;
      case "latlon":
        return <LatLonIcon style={{ color: "blue" }} />;
      default:
        return null; // return <LocationOnIcon style={{ color: 'text.secondary' }} />;
    }
  };

  function renderOption(props, option, inputValue) {
    const { key, ...optionProps } = props;
    //console.log(option);
    const matches = match(option.label, inputValue);
    const parts = parse(option.label, matches);

    return (
      <li key={key} {...optionProps}>
        <Grid container sx={{ alignItems: "center" }}>
          <Grid item sx={{ display: "flex", width: 30 }}>
            {getIcon(option.id_tag)}
          </Grid>
          <Grid item sx={{ width: "35px", wordWrap: "break-word" }}>
            <Box color="text.secondary" component="span">
              <small>{option.desc}</small>
            </Box>
          </Grid>
          <Grid
            item
            sx={{ width: "calc(100% - 65px)", wordWrap: "break-word" }}
          >
            {parts.map((part, index) => (
              <Box
                key={index}
                component="span"
                sx={{ fontWeight: part.highlight ? "bold" : "regular" }}
              >
                {part.text}
              </Box>
            ))}
            {/* <Typography variant="body2" color="text.secondary">{option.desc}</Typography> */}
          </Grid>
        </Grid>
      </li>
    );
  }

  return (
    <StyledAutoComplete
      id="autocomplete-sites"
      filterOptions={(x) => x}
      forcePopupIcon={false}
      noOptionsText="Type text to find locations (name or lat,lon)"
      autoHighlight
      clearOnBlur={false}
      blurOnSelect
      //filterSelectedOptions
      //autoComplete
      clearOnEscape
      sx={{ width: "60ch", paddingTop: "10px", paddingBottom: "10px", paddingLeft: "10px", paddingRight: "10px" }}
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}      
      renderInput={(params) => (
        <StyledTextField
          {...params}          
          placeholder="Search site"
          variant="outlined"
          inputRef={autocompleteRef}
          InputProps={{
            ...params.InputProps,            
            startAdornment: (!inputValue) ? ( <InputAdornment position="start"><SearchIcon /></InputAdornment> ) : null, 
            onKeyDown: (e) => {
                if (e.key === 'Enter') {
                  //e.stopPropagation();
                  console.log('enter pressed, text value '+e.target.value); // console.log(e.target);
                  setOpen(false);                                    
                  e.target.blur();
                  handleOnValidate(); //handleOnChange(e, {label:e.target.value});
                }
            },          
            //endAdornment: isLoading ? (<LoadingIcon color="inherit" size={20} />) : null,
            endAdornment: inputValue ? (
              <>                
                <IconButton color="primary" aria-label="search" size="small" onMouseDown={ (e) => {                      
                     handleOnValidate();
                     if (autocompleteRef.current) {
                       console.log(autocompleteRef.current);
                       autocompleteRef.current.blur(); // querySelector('input')                       
                     }                                          
                    } }>  
                  <SearchIcon color="inherit" size={20} />
                 </IconButton>  
                {params.InputProps.endAdornment}
              </>
            ) : null
          }}
        />
      )}
      onInputChange={handleOnInputChange}
      options={options}
      renderOption={(props, option) => renderOption(props, option, inputValue)}
      isOptionEqualToValue={handleIsOptionEqualToValue}
      onChange={handleOnChange}
    />
  );
}

export default AutoCompleteSite;
