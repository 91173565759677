import React, { useState, useRef } from "react";

import WorldIcon from "@mui/icons-material/Flag";
//import WorldIcon from "@mui/icons-material/TravelExplore";
import MyPositionIcon from "@mui/icons-material/GpsFixed"; // Adjust";
import LayersIcon from "@mui/icons-material/Layers";
import InfoIcon from "@mui/icons-material/Info";
import CloudIcon from "@mui/icons-material/CloudOutlined";
import DiveIcon from "@mui/icons-material/ScubaDiving";

import OpenLayerMap from "./components/OpenLayerMap";
import PanelTabsOfMapInfo from "./components/PanelTabsOfMapInfo";
import PanelBottomInfo from "./components/PanelBottomInfo";
import ButtonGeoLoc from "./components/ButtonGeoLoc";
import AppBarGD from "./components/AppBarGD";


const LAYERS_GROUP = [
  { label: "Maps", layerType: "background", icon: LayersIcon, exclusive:true  },
  { label: "Sea state",  layerType: "weather",  icon: CloudIcon,  exclusive:true },
  { label: "Info",       layerType: "info",       icon: InfoIcon,   exclusive:false },
];


const App = () => {
  const mapRef = useRef(null);
  const [txtMouseLatLon, SetTxtMouseLatLon] = useState('');
  const [txtMouseInfo, SetTxtMouseInfo] = useState('');
  
  const getMap = () => {
    return mapRef.current;
  }


  return (
    <div style={{ display: "flex", flexDirection: "column", height: "100vh" }}>

      <AppBarGD getmap={getMap} />

      {/* My Loc Button 
      <ButtonRound id="geolocButton" label="account" icon={<MyPositionIcon />} bottom="75px" right="34px" size="small" /> */}

      {/* OpenLayer Map */}
      <OpenLayerMap id="olmap" ref={mapRef} id_mouse_latlon="div_mouse_latlon" id_label_mouseover="mouse_info" />
     
      <PanelTabsOfMapInfo 
        getmap={getMap}      
        btnTop="140px" // 170
        btnRight="15px"
        defaultActivePage={0}
        btnIcon={<DiveIcon />}
      />       

      {/*
      <IconButton size="medium" style={{ position: "absolute", top:"85px", right:"20px" }} >
         <MyPositionIcon />
      </IconButton>  */}
      <ButtonGeoLoc getmap={getMap} /> 
       
      <PanelTabsOfMapInfo 
        getmap={getMap}      
        btnTop="210px" // 100
        btnRight="15px"
        defaultActivePage={1}
        btnIcon={<WorldIcon />}
      />      

      <PanelTabsOfMapInfo 
        getmap={getMap}      
        btnTop="280px" // 240
        btnRight="15px"
        defaultActivePage={2}
        btnIcon={<LayersIcon />}
      /> 
                      
      <PanelBottomInfo txtLatlonMouse={txtMouseLatLon} txtSiteInfoMouse={txtMouseInfo} />

    </div>
  );
};

export default App;
