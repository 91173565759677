import React, { useState, useEffect, useCallback, useMemo, useRef } from "react";
import { debounce } from "@mui/material/utils";

import { fromLonLat } from "ol/proj.js";

import Point from 'ol/geom/Point.js';
import {Circle as CircleStyle, Stroke, Style} from 'ol/style.js';
import {easeOut} from 'ol/easing.js';
import {getVectorContext} from 'ol/render.js';
import {unByKey} from 'ol/Observable.js';


import { apiAutoComplete, apiGetLocLatLon, AUTOCOMPLETE_BOUNCE_DELAY } from "../conf";
import AutoCompleteSite from "./AutoCompleteSite";

// ====================== Component =====================

function AppBarSearch({ getmap }) {
  const [value, setValue] = useState(null); // selected value {id_tag, value, label} id_tag = gmap, site, port, latlon
  //const idValueRef = useRef(null);
  const [inputValue, setInputValue] = useState(""); // text typed by user in input element
  const [options, setOptions] = useState([]); // option list (based on autocomplete from backend)
  const [isLoading, setIsLoading] = useState(false);
  const [controller, setController] = useState(null);

  // load options from backend autocomplete API
  const fetchOptionsFromAPI = async (inputValue, signal) => {
    console.log("Call fetchOptionsFromAPI Start : " + inputValue);
    setIsLoading(true);
    try {
      const url = apiAutoComplete + "/?term=" + inputValue;
      const response = await fetch(url, { signal });
      const data = await response.json();
      return data["results"];
    } catch (error) {
      if (error.name === "AbortError") {
        console.log("Fetch aborted");
      } else {
        console.error("Fetch error:", error);
      }
      return [];
    } finally {
      setIsLoading(false);
    }
  };
  
// Request Debounced With Memo
const debouncedFetch = useMemo(() => {
  return debounce(async (query, signal) => {
    if (query) {
      const fetchedOptions = await fetchOptionsFromAPI(query, signal);
      setOptions(fetchedOptions);
    }
  }, AUTOCOMPLETE_BOUNCE_DELAY);
}, []);

// init option list
useEffect(() => {
  if (inputValue) {
    if (controller) {
      controller.abort(); // cancel previous request
    }
    const newController = new AbortController();
    setController(newController);
    debouncedFetch(inputValue, newController.signal);
  } else {
    setOptions([]);
  }
}, [inputValue, debouncedFetch]);



function flash(lon,lat, item_radius) {
  const duration = 3000;
  const start = Date.now();
  const flashGeom = new Point(fromLonLat([lon, lat]));
  let sites_layer = getmap().getLayers().getArray().filter((layer) => layer.get("title") === "Sites")[0];
  const listenerKey = sites_layer.on('postrender', animate);

  function animate(event) {
    const frameState = event.frameState;
    const elapsed = frameState.time - start;
    if (elapsed >= duration) {
      //start = frameState.time;
      unByKey(listenerKey);
      return;
    }
    const vectorContext = getVectorContext(event);
    const elapsedRatio = elapsed / duration;
    // radius will be 5 at start and 30 at end.
    const radius = easeOut(elapsedRatio) * item_radius + 5;  // easeOut(elapsedRatio) * 25 + 5;
    const opacity = easeOut(1 - elapsedRatio);

    const style = new Style({
      image: new CircleStyle({
        radius: radius,
        stroke: new Stroke({
          color: 'rgba(255, 0, 0, ' + opacity + ')',
          width: 0.5 + opacity, //0.25 + opacity,
        }),
      }),
    });

    vectorContext.setStyle(style);
    vectorContext.drawGeometry(flashGeom);
    // tell OpenLayers to continue postrender animation
    getmap().render();
  }
}

  // data load from API
  const fetchLocLatLonFromAPI = async () => {
    console.log('call fetchLocLatLonFromAPI');
    let data;
    // console.log("call fetchLocLatLonFromAPI : " + value.id_tag + " : " + value.value);
    if (!value || value.label === "") return;        

    try {
      if (value.id_tag === "latlon") {
        const latlon = value.value.split(",");
        data = { results:{item_select_type: "latlon", item_select_id:value.value, item_select_name:"Loc", lat:latlon[0], lon:latlon[1]}}
        console.log(data);

      } else {        
        const query = (value.id_tag && value.value) ? `select-item-id=${value.id_tag}:${value.value}` : `select-item-str=${value.label}`;
        const url = `${apiGetLocLatLon}/?${query}`; // decodeURIComponent
        console.log(`fetchLocLatLonFromAPI : ${url}`);
        const response = await fetch(url);
        data = await response.json();
      }
      
      //const info = data["results"];
      //console.log(info);
      if (data["results"] && data["results"].lat && data["results"].lon) {                 
        //setInputValue(data["results"].item_select_name);
        const animDef = {center:fromLonLat([data["results"].lon, data["results"].lat]), rotation:0, duration:0};
        switch(data["results"]["item_select_type"]) {
          case "city_gmap": animDef.resolution = 30;
                            break;
          case "port":      animDef.resolution = 12;
                            break;
          case "dive_site": animDef.resolution = 6;
                            break;
          case "latlon":    animDef.duration = 700;
                            break;
        }
        //const resolution = (data["results"]["item_select_type"] === "city_gmap") ? 30 : 3.9;      
        //const animDef = {center:fromLonLat([data["results"].lon, data["results"].lat]), resolution: resolution, rotation:0, duration:0};
        console.log(animDef);
        getmap().getView().animate(animDef); 

        const radius = (data["results"]["item_select_type"] === "city_gmap") ? 100 : 40;
        flash(data["results"].lon, data["results"].lat, radius);
      } 

    } catch (error) {
      console.error("Fetch error:", error);
      return [];
    } 
  };

  function onValidateSearch() {
    if (!value || (value.value !== inputValue)) {
      setValue({label:inputValue, value:inputValue});
    }
    //console.log(value);
    fetchLocLatLonFromAPI();
  }

  // when value is set (option selected or text with enter)
  useEffect(() => {      
    if (!value || !getmap()) return;
    console.log('useEffect on value change : ',value.label, value.id_tag, value.value);
    fetchLocLatLonFromAPI();
  }, [value]);  

  return (
    <AutoCompleteSite
      options={options}
      inputValue={inputValue}
      
      handleOnInputChange={(event, newInputValue) => {
        console.log('handleOnInputChange');
        setInputValue(newInputValue);
      }}
      handleOnChange={(event, newValue) => {
        if (newValue) console.log("onchange : new value = " + newValue.label + ", "+newValue.id_tag);
        setValue(newValue);
      }}

      handleOnValidate={onValidateSearch}

      handleIsOptionEqualToValue={(option, value) => {
        //console.log("option.label = " + option.value, "value.label = " + value.label );
        return option.value === value.value;
      }}
    />
  );
}

export default AppBarSearch;
