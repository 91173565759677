//import React from "react";
import { useState, useEffect } from 'react';
import Divider from '@mui/material/Divider';
import Chip from '@mui/material/Chip';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';

import Panel from "./Panel";


function a11yProps(index) {
  return {
    id: `panel-tab-${index}`,
    "aria-controls": `panel-tabpanel-${index}`,
    style: { minWidth: 45, maxWidth: 45, width: 45 },
  };
}


function PanelTabs({ tabsInfo, defaultActivePage=0, tabPagesRenderingFunction, ...props }) {
  const [activeTabPage, setActiveTabPage] = useState(defaultActivePage);
  const [openMenu, setOpenMenu] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);  

  function onDisplayPanel() {
    setActiveTabPage(defaultActivePage);
  };
 
  const onChangeTabPage = (event, newValue) => {
    setActiveTabPage(newValue);
  };

  const onClickTitle = () => {
    if (activeTabPage+1 < tabsInfo.length)
      setActiveTabPage(activeTabPage+1)
    else
    setActiveTabPage(0);
  };


  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
    setOpenMenu(true);
    //onClickArea(areaGroup);    
  };

  const handleClickMenuItem = (id) => {
    handleCloseMenu();
    setActiveTabPage(id);    
  };  

  const handleCloseMenu = () => {
    setOpenMenu(false);
  };  


  const ActiveIcon=tabsInfo[activeTabPage].icon;
  
  return (
    <Panel
      panelTitle={tabsInfo[activeTabPage].label}
      onDisplayPanel={onDisplayPanel}
      {...props}
    >
      {/*
      <Tabs
        value={activeTabPage}
        aria-label="layers tabs"
        onChange={onChangeTabPage}
        variant="scrollable"
        scrollButtons="auto"        
        centered
      >
        {tabsInfo.map((tab, i) => (
          <Tab label={tab.label} value={i} icon={<tab.icon /> } wrapped {...a11yProps(i)} />
        ))}
        </Tabs> */}

{/*
      <Tabs
        value={activeTabPage}
        aria-label="layers tabs"
        onChange={onChangeTabPage}
        variant="scrollable"
        scrollButtons="auto"
        //keepMounted={true}
      >
        {tabsInfo.map((tab, i) => (
          <Tab value={i} icon={<tab.icon />} {...a11yProps(i)} />
        ))}
      </Tabs> */}

      {/*
      <Typography
            variant="h7"
            align="center"
            style={{ margin: "5px" }}
            //onClick={() => setPanelVisibility(false)}
          > 
           <center><b>{tabsInfo[activeTabPage].label}</b></center>
      </Typography>      
        <Divider /> */}
        
        <center>
        
          <b>            
            <Chip
              label={tabsInfo[activeTabPage].label}
              color="primary"
              icon={<ActiveIcon />}
              //deleteIcon={<ActiveIcon />}
              style={{ marginTop: "15px", marginBottom:"15px", width: "100%" }}
              //onClick={onClickTitle}
              onClick={(event) => handleOpenMenu(event)}
              //onDelete={onClickTitle}             
            />
          </b>
        </center>

        <Menu 
            //id={"menu_"+itemId}
            open={openMenu}
            anchorEl={anchorEl}
            onClose={handleCloseMenu}
          >                      
              {tabsInfo.map((tab, i) => {
                return( <MenuItem 
                           key={`${tab.label}_${i}`}
                           selected={i === activeTabPage} 
                           //style={{color:"#1769aa" }} 
                           onClick={ () => handleClickMenuItem(i) }>
                             <ListItemIcon>{<tab.icon fontSize="small" />} </ListItemIcon>
                             <ListItemText>{tab.label}</ListItemText>                            
                        </MenuItem>);
              })}

            <Divider sx={{ my: 0.5 }} />
            <MenuItem key='menuitem-close' onClick={handleCloseMenu} disableRipple>
              <small><b>Close</b></small>
            </MenuItem>  

          </Menu>        

      {/*
      <ButtonGroup variant="contained">
        <Button size="small">
          <LeftIcon />
        </Button>
        <Button size="small">{tabsInfo[activeTabPage].label}</Button>
        <Button size="small">
          <RightIcon />
        </Button>
      </ButtonGroup> */}

      {/*
      <ToggleButtonGroup
        orientation="vertical"
        value={activeTabPage}
        exclusive
        onChange={onChangeTabPage}        
        >      
        {tabsInfo.map((tab, i) => (
          <ToggleButton value={i} aria-label="list" fullWidth={true} style={{justifyContent: "flex-start"}} ><tab.icon style={{ paddingRight:10 }} /> {tab.label}</ToggleButton>
        ))}
        </ToggleButtonGroup>  */}

      {tabPagesRenderingFunction(activeTabPage)}
    </Panel>
  );
}

export default PanelTabs;
