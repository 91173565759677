// general config parameters


let url = window.location.protocol+'//'+window.location.hostname;
if (process.env.NODE_ENV === 'production') {
    console.log('PRODUCTION MODE');    
} else {
    console.log('NOT IN PRODUCTION MODE');
    url = url + ':8000';
}

//const backendDomain = 'https://www.geo-dive.com/back';
//const backendDomain ='http://192.168.1.89:8000/back';
//const url = window.location.protocol+'//'+window.location.hostname;

const backendDomain = url + '/back';
const backendDomainSites = backendDomain + '/sites/api';
//console.log(backendDomain);

export const apiGetDiveSites = backendDomainSites + '/divesites';
export const apiGetCityPorts = backendDomainSites + '/cityports';
export const apiAutoComplete = backendDomainSites + '/autocomplete';
export const apiGetLocLatLon = backendDomainSites + '/locationinfo';
export const apiGetDivesiteInfo = backendDomainSites + '/divesiteinfo';

export const apiAdminEditDiveSite = backendDomain + '/admin/sites/site';


export const AUTOCOMPLETE_BOUNCE_DELAY = 400; //200;

