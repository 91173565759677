// ------------------------------------------------------------------------------------------------------------
//                             Function to compute datetime rouded in the passt
// ------------------------------------------------------------------------------------------------------------

export const getUTCDateEarlierRounded = (hoursToSubtract, minutesToSubtract, roundingMinutes) => {
  const currentDateUTC = new Date(); // Date actuelle en UTC

  // Soustraire les heures et les minutes
  currentDateUTC.setUTCHours(currentDateUTC.getUTCHours() - hoursToSubtract);
  currentDateUTC.setUTCMinutes(currentDateUTC.getUTCMinutes() - minutesToSubtract);

  // Arrondir selon le nombre de minutes spécifié
  return roundToPreviousMinuteUTC(currentDateUTC, roundingMinutes);
};

const roundToPreviousMinuteUTC = (date, roundingMinutes) => {
    const minutes = date.getUTCMinutes();
    
    // Arrondir les minutes au multiple inférieur de roundingMinutes
    const roundedMinutes = Math.floor(minutes / roundingMinutes) * roundingMinutes;
    date.setUTCMinutes(roundedMinutes);
    date.setUTCSeconds(0); // Remettre les secondes à 0
    date.setUTCMilliseconds(0); // Remettre les millisecondes à 0
    return date;
  };

/*
const roundToPreviousQuarterHourUTC = (date) => {
  const minutes = date.getUTCMinutes();
  const roundedMinutes = Math.floor(minutes / 15) * 15;
  date.setUTCMinutes(roundedMinutes);
  date.setUTCSeconds(0);
  date.setUTCMilliseconds(0);
  return date;
};

const roundToPreviousHourUTC = (date) => {
  date.setUTCMinutes(0); // Remettre les minutes à 0
  date.setUTCSeconds(0); // Remettre les secondes à 0
  date.setUTCMilliseconds(0); // Remettre les millisecondes à 0
  return date;
};

export const getUTCDateMinutesEarlierRounded = (minutes) => {
  const currentDateUTC = new Date();
  currentDateUTC.setUTCMinutes(currentDateUTC.getUTCMinutes() - minutes);
  return roundToPreviousQuarterHourUTC(currentDateUTC);
}; 

export const getUTCDateHoursEarlierRounded = (hours) => {
  const currentDateUTC = new Date();
  currentDateUTC.setUTCHours(currentDateUTC.getUTCHours() - hours);
  return roundToPreviousHourUTC(currentDateUTC);
}; */
