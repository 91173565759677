import React, { useEffect, useRef, useState } from "react";
//import Drawer from "@mui/material/Drawer";
//import Box from "@mui/material/Box";
//import Fab from "@mui/material/Fab";

import "ol/ol.css"; // Importez les styles CSS d'OpenLayers
import Map from "ol/Map";
import View from "ol/View";
import { fromLonLat } from "ol/proj.js";
import ScaleLineControl from "ol/control/ScaleLine";
import { Attribution } from 'ol/control';
import { toLonLat } from "ol/proj";

import PanelFeatureInfo from "./PanelFeatureInfo";

import createAllLayersGroups from "../layers/LayersGroupAll";
import { create_site_feature_layer, create_port_feature_layer } from "../layers/DiveSitesLayers"



const OpenLayerMap = React.forwardRef(({id_mouse_latlon, id_label_mouseover}, ref) => {
  const mapRef = useRef(null);
  const [panelVisible, setPanelVisibility] = useState(false);
  //let highlightedFeature = null;
  const [highlightedFeature, setHighlightedFeature] = useState(null);
  const [selectedFeature, setSelectedFeature] = useState(null);

  const [olmap, SetMap] = useState(null);

  function onMouseMove(evt, map, sites_layer) {
    if (evt.dragging) { return; }
    // display mouse lat/lon    
    const div = document.getElementById(id_mouse_latlon);
    if (div) {        
      const coordinate = toLonLat(evt.coordinate).map(function(val) {
        return val.toFixed(5);
      });        
      div.innerHTML = coordinate[1] + ' ' + coordinate[0] + ' - resolution '+map.getView().getResolution().toFixed(2); 
    }    
                     
    // if resolution Ok, show info on highlighted feature (site)
    if (map.getView().getResolution() < 200) {
      const feature = map.forEachFeatureAtPixel(evt.pixel, function(feature,layer) {
          return feature;
          }, {
            layerFilter: function(layer) {
                return layer.get('title') === 'Sites';
            }
      });

      const info_mousemove = document.getElementById(id_label_mouseover);
      if (info_mousemove) {
        info_mousemove.innerHTML = (feature) ? feature.get('name')+" ("+feature.get('floor_depth')+"m)" : "";        
      }  

      if (feature) {
        sites_layer.set("highlightedFeature", feature.get("id"));
        feature.changed();       
      }
       else if (sites_layer.get("highlightedFeature") !== "") {
        sites_layer.set("highlightedFeature", "");
        sites_layer.getSource().changed();       
      } 
    } 

  }
  
  function onMapMoveEnd(map, sites_layer) {
    const resolution = map.getView().getResolution();
    if ((resolution > 25000) && (sites_layer.getOpacity !== 0.1)) { sites_layer.setOpacity(0.1); }
    else if ((resolution > 14000) && (sites_layer.getOpacity !== 0.2)) { sites_layer.setOpacity(0.2); }
    else if ((resolution > 8000) && (sites_layer.getOpacity !== 0.3)) { sites_layer.setOpacity(0.3); }
    else if ((resolution > 3000) && (sites_layer.getOpacity !== 0.4)) { sites_layer.setOpacity(0.4); }
    else if ((resolution > 1000) && (sites_layer.getOpacity !== 0.6)) { sites_layer.setOpacity(0.6); }
    else if ((resolution > 100) && (sites_layer.getOpacity !== 0.8)) { sites_layer.setOpacity(0.8); }
    else if (sites_layer.getOpacity != 1) { sites_layer.setOpacity(0.8); }    
  }


  function OnClickMap(evt, map, sites_layer) {

    const feature = map.forEachFeatureAtPixel(evt.pixel, function(feature,layer) {
      return feature;
    }, {hitTolerance:40}); 

    if (feature) {
      sites_layer.set("selectedFeature", feature.get("id"));
      feature.changed();
      map.renderSync();
      setPanelVisibility(true);
    }
    else if (sites_layer.get("selectedFeature") !== "") {
      sites_layer.set("selectedFeature", "");
      sites_layer.getSource().changed();       
      setPanelVisibility(false);
    }  
    
    setSelectedFeature(feature);  

  }

  useEffect(() => {    
    const scaleLineControl = new ScaleLineControl({}); //target: "scaleline-id", //document.getElementById("scaleline-id"),
    const attrControl = new Attribution();

    // - create map -
    const map = new Map({
      target: mapRef.current, // ref de l'élément HTML où la carte sera rendue
      controls: [scaleLineControl, attrControl], //, mousePositionControl],
      layers: createAllLayersGroups(), //InitMapBgLayers(),
      view: new View({
        projection: 'EPSG:3857', 
        center: fromLonLat([0, 0]), // Centre de la carte
        zoom: 1, // Niveau de zoom initial
      }),
    });

    SetMap(map);

    // - init user lat/lon to center domain -
    fetch('https://ipapi.co/json/') 
    .then(response => response.json())
    .then(data => { 
      map.setProperties({ userLat: data.latitude, userLon: data.longitude });      
      const animDef = { center: fromLonLat([data.longitude, data.latitude]), zoom: 7 }; 
      map.getView().animate(animDef);      
    });  

    // - add layers -
    const layer_sites = create_site_feature_layer();
    map.addLayer(layer_sites);
    const layer_ports = create_port_feature_layer();
    map.addLayer(layer_ports);    

    // - lat/lon display on mouse move -
    map.on('pointermove', (evt) => onMouseMove(evt, map, layer_sites));
    map.on('click', (evt) => OnClickMap(evt, map, layer_sites));
    map.on('moveend', () => onMapMoveEnd(map, layer_sites));

    // - Pass the map reference to the parent component -
    if (ref) {
      ref.current = map;
    }

    // Nettoyage de la carte lors de la suppression du composant
    return () => {
      map.setTarget(null); // ou map.dispose();
    };
  }, [ref]); // Le tableau vide signifie que cette fonction ne s'exécutera qu'une seule fois après le montage initial

  return (
    <>
     <div id="id_map" ref={mapRef} style={{ width: "100%", height: "100%", flex: 1 }}></div>     
     <PanelFeatureInfo map={olmap} panelVisible={panelVisible} feature={selectedFeature} onClose={() => setPanelVisibility(false)} />
    </>
  );
});

export default OpenLayerMap;
