import TileLayer from "ol/layer/Tile";
import TileWMS from "ol/source/TileWMS";
import LayerGroup from 'ol/layer/Group.js';


// return a table of WMS layer with url and layer anmes 
function createWMSLayers(url, tabLayersName) {
  return tabLayersName.map(layerName => new TileLayer({
    source: new TileWMS({
      url,
      params: { LAYERS: layerName, TILED: true },
      transition: 0,
    }),
  }));
}

// return a table of WMS layer with url and layer anmes 
function createWMSLayers2(url, tabLayersName) {
  //let extent = [-1.201857,51.87670,-1.093555,51.928750];
  //let new_extent = ol.proj.transformExtent(extent,'EPSG:4326','EPSG:3857')

  return tabLayersName.map(layer => new TileLayer({
    extent: layer.extent,
    source: new TileWMS({
      url,
      params: { LAYERS: layer.name, TILED: true },
      transition: 0,
    }),
  }));
}


// Definition of Map background layers
// zindex : 0 = behind (ie image), 10 = intermediate, 100 = top
export default function createLayersGroupBathy(categoryId="bathy") {

  // world
  const gebco_layer = new TileLayer({
    title: "Bathy (World)",    
    layerType: categoryId,
    visible: false,    
    zIndex: 10,
    opacity: 0.6,
    //layer_icon: "images/icon_IsoDepth.png",
    layer_icon: "images/icon_layer_bg_bathy_90x60.png",
    source: new TileWMS({
      //url: "https://www.gebco.net/data_and_products/gebco_web_services/web_map_service/mapserv",
      url: "https://wms.gebco.net/mapserv",
      params: { LAYERS: "GEBCO_LATEST_2", TILED: true },
      transition: 0,
    }),    
  });  


  // Europe
  const bathy_emodnet_layer = new TileLayer({
    title: "Bathy (Europe)",
    layerType: categoryId,
    zIndex: 11,  
    visible: false,
    opacity: 0.6,    
    //minZoom: 5,
    layer_icon: "images/icon_layer_bg_bathy_90x60.png",
    source: new TileWMS({
      url: "https://ows.emodnet-bathymetry.eu/wms",
      // params: {'LAYERS': 'emodnet:mean_atlas_land', 'TILED': true},
      params: { LAYERS: "emodnet:mean_multicolour", TILED: true },
      //serverType: 'geoserver',
      // Countries have transparency, so do not fade tiles:
      transition: 0,
    }),    
  });  

  /*
  const opendem_layer = new TileLayer({
    title: "Depth contours 1",    
    layerType: categoryId,
    visible: false,
    zIndex: 100,
    opacity: 0.4,
    layer_icon: "images/icon_IsoDepth.png",
    source: new TileWMS({
      url: "https://www.opendem.info/geoserver/opendem/wms",
      params: { LAYERS: "gebco_2021_contours", TILED: true },
      transition: 0,
    }),
  }); */

  const layer_emodnet_contours = new TileLayer({
    title: "Depth contours",
    layerType: categoryId,
    visible: false,
    zIndex: 100,
    //opacity: 0.6,
    layer_icon: "images/icon_IsoDepth.png",
    source: new TileWMS({
      url: "https://ows.emodnet-bathymetry.eu/wms",
      params: { LAYERS: "emodnet:contours", TILED: true },
      transition: 0,
    }),    
  });

  const layer_group_bathy_emodnet_undersea = new TileLayer({
    title: "Undersea names for bathy",
    layerType: categoryId,
    visible: false,
    zIndex: 100,    
    opacity: 1,
    layer_icon: "images/icon_IsoDepth.png",
    
    source: new TileWMS({
      url: "https://ows.emodnet-bathymetry.eu/wms",
      params: { LAYERS: "gebco:undersea_features", TILED: true },  // world:sea_names
      transition: 0,
    }),    
  }); 


 // ================================================================================================
 //
 //                                          Litto 3D France
 //
 // ================================================================================================

// https://services.data.shom.fr/INSPIRE/wms/r?SERVICE=WMS&request=GetCapabilities

  const layer_group_shom_Litto3D = new LayerGroup({
    title: "French coast 3D sea",
    layerType: categoryId,
    visible: true,
    zIndex: 12,
    opacity: 0.6,
    maxResolution: 23, 
    layer_icon: "images/icon_layer_info_litto3Dfr_90x60.png",
    layers: createWMSLayers("https://services.data.shom.fr/INSPIRE/wms/r", [
      "L3D_MAR_LR_2011_PYR_3857_WMSR",
      "L3D_MAR_LR_2014_2015_WMSR_3857",
      "LITTO3D_PACA_2015_PYR_3857_WMSR",
      "L3D_LIDAR_CORSE_2017_2018_PYR_3857_WMSR",
      "L3D_MAR_AQT_2020_2022_PYR_3857_WMSR",
      "L3D_MAR_BZH_2018_2021_WMSR_3857",
      "L3D_MAR_MORBIHAN_2015_PYR_3857_WMSR",
      "L3D_MAR_NHDF_2016_2018_PYR_3857_WMSR",
      "L3D_MAR_PNMI_2012_PYR_3857_WMSR"
    ]),

    legend_description: 'Litto3D Marine Part is an intermediate product of the Litto3D product containing only marine data.Litto3D is a unique and continuous land-sea database which can provide 3D models of the shape and location of French coastal terrain. A line separates sea and land data acquisition zones, and the associated survey readings obtained using bathymetric lidar technologies or multibeam echosounders for sea readings and airborne lidar topographical surveys for land readings, with 300 m overlap. Litto3D digital models are available in two formats: a set of 3D points and a qualified terrain mesh.',
    legend_url_graphic: 'https://services.data.shom.fr/static/legends/FR/MNT_LITTORAL_LITTO3D/legende_litto3d_LR_PACA.png',
    legend_url_info: 'https://services.data.shom.fr/geonetwork/INSPIRE?service=CSW&version=2.0.2&request=GetRecordById&Id=BATHYMETRIE_L3D_MAR_LR_2011.xml',
  });    

  
  const layer_group_shom_MNTBathy = new LayerGroup({
    title: "French coast 3D land",
    layerType: categoryId,
    visible: false,
    zIndex: 11,
    opacity: 0.6,
    //maxResolution: 23, 
    layer_icon: "images/icon_layer_info_litto3Dfr_90x60.png",
    layers: createWMSLayers("https://services.data.shom.fr/INSPIRE/wms/r", [
      "LITTO3D_STBARTHELEMY_2019_PYR_3857_WMSR",
      "LITTO3D_STMARTIN_2019_PYR_3857_WMSR",
      "LITTO3D_GUAD_2016_PYR_3857_WMSR",
      "LITTO3D_MART_2016_PYR_3857_WMSR",
      "LITTO3D_BZH_2018_2021_PYR_3857_WMSR",
      "LITTO3D_FINISTR_2014_PYR_3857_WMSR",
      "LITTO3D_LR_2009_PYR_3857_WMSR",
      "LITTO3D_PACA_2015_PYR_3857_WMSR",
      "LITTO3D_EPARSES_2012_PYR_3857_WMSR",
      "LITTO3D_MAYOT_2012_PYR_3857_WMSR",
      "LITTO3D_REUNION_2016_PYR_3857_WMSR"
    ]),

    legend_description: 'Litto3D Marine Part is an intermediate product of the Litto3D product containing only marine data.Litto3D is a unique and continuous land-sea database which can provide 3D models of the shape and location of French coastal terrain. A line separates sea and land data acquisition zones, and the associated survey readings obtained using bathymetric lidar technologies or multibeam echosounders for sea readings and airborne lidar topographical surveys for land readings, with 300 m overlap. Litto3D digital models are available in two formats: a set of 3D points and a qualified terrain mesh.',
    legend_url_graphic: 'https://services.data.shom.fr/static/legends/FR/MNT_LITTORAL_LITTO3D/legende_litto3d_LR_PACA.png',
    legend_url_info: 'https://services.data.shom.fr/geonetwork/INSPIRE?service=CSW&version=2.0.2&request=GetRecordById&Id=BATHYMETRIE_L3D_MAR_LR_2011.xml',
  });  


  const test = new LayerGroup({
    title: "Test bathy",
    layerType: categoryId,
    visible: true,
    zIndex: 12,
    opacity: 0.6,
    //maxResolution: 23, 
    layer_icon: "images/icon_layer_info_litto3Dfr_90x60.png",
    layers: createWMSLayers2("https://services.data.shom.fr/INSPIRE/wms/r", [
      // extent is [minx, miny, maxx, maxy]
      // BoundingBox CRS="EPSG:3857" maxx="496171.809000000" maxy="5397672.736000000" minx="336664.466000000" miny="5224516.233000000"
      { name:"L3D_MAR_AQT_2020_2022_PYR_3857_WMSR", extent: [-200569.734000000,5364139.738000000,-106106.201000000,5834339.946000000 ] }, // nouvelle-aquitaine
      { name:"L3D_MAR_LR_2011_PYR_3857_WMSR", extent: [336664.466000000, 5224516.233000000, 496171.809000000, 5397672.736000000] }, // languedoc-roussillon 2011      
      { name:"L3D_MAR_LR_2014_2015_WMSR_3857", extent: [329888.077000000,5238104.480000000,494820.639000000,5400362.536000000] }, // languedoc-roussillon 2014
      //{ name: "LITTO3D_LR_2009_PYR_3857_WMSR", extent: [323088.445000000, 5224518.906000000, 503970.575000000, 5428633.093000000] },
      { name:"L3D_MAR_MORBIHAN_2015_PYR_3857_WMSR", extent: [-333419.287000000,6026477.249000000,-298895.287000000,6049681.249000000 ] } // Morbihan 2015
    ]),

    legend_description: 'Litto3D Marine Part is an intermediate product of the Litto3D product containing only marine data.Litto3D is a unique and continuous land-sea database which can provide 3D models of the shape and location of French coastal terrain. A line separates sea and land data acquisition zones, and the associated survey readings obtained using bathymetric lidar technologies or multibeam echosounders for sea readings and airborne lidar topographical surveys for land readings, with 300 m overlap. Litto3D digital models are available in two formats: a set of 3D points and a qualified terrain mesh.',
    legend_url_graphic: 'https://services.data.shom.fr/static/legends/FR/MNT_LITTORAL_LITTO3D/legende_litto3d_LR_PACA.png',
    legend_url_info: 'https://services.data.shom.fr/geonetwork/INSPIRE?service=CSW&version=2.0.2&request=GetRecordById&Id=BATHYMETRIE_L3D_MAR_LR_2011.xml',
  });    



  return [   
    //opendem_layer,
    gebco_layer,
    bathy_emodnet_layer,
    layer_emodnet_contours,
    layer_group_bathy_emodnet_undersea,
    layer_group_shom_Litto3D,
    layer_group_shom_MNTBathy,
    test
  ];
}
