import React, { useState } from "react";
import Box from '@mui/material/Box';
import Typography from "@mui/material/Typography";
import IconButton from '@mui/material/IconButton';

import MyPositionIcon from "@mui/icons-material/GpsFixed"; // Adjust";

function PanelBottomInfo({ txtLatlonMouse, txtSiteInfoMouse, ...props }) {
    //const [txtLatlonMouse, setTxtLatlonMouse] = React.useState(''); 

    return (      
      <div
        id="div_info"
        style={{
          position: "fixed", // "absolute", //
          bottom: 0,
          //top: 300,
          left: 0,
          width: "100%",
          height: 40,
          backgroundColor: "rgba(169, 169, 169, 0.6)",
          display: "flex",
          alignItems: "center",
          //zIndex: 1201,// mui drawer: 1200 see https://mui.com/material-ui/customization/z-index/
          //justifyContent: "flex-end",
        }}
      >
        <Box sx={{ flexGrow: 1 }} />

        <Typography id="mouse_info" variant="button" style={{ fontWeight: "bold" }}>{txtSiteInfoMouse}</Typography>

        <Typography variant="body2" style={{ width: "15px" }}></Typography>

        <Typography variant="body2">lat,lon</Typography>
        <Typography
          id="div_mouse_latlon"
          variant="body2"
          style={{ width: "350px", marginLeft: "10px" }} // 170px
        >{txtLatlonMouse}</Typography>

      </div>
    );
}

export default PanelBottomInfo;