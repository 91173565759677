import React, { useState, useEffect } from "react";
import ImageList from "@mui/material/ImageList";
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
//import FormHelperText from '@mui/material/FormHelperText';


import ButtonImage from "./ButtonImage";


const BUTTONS_SITETYPES = [
  { label: "ALL SITES",  value: "ALL",       image: "images/icon_divesite_all.png" },
  { label: "FAMOUS",     value: "FAMOUS",    image: "images/icon_divesite_famous_sites.png" },
  { label: "EXPLO",      value: "EXPLO",     image: "images/icon_divesite_explo.png" },
  { label: "WRECKS",     value: "WRECKS",    image: "images/icon_divesite_wreck2.png" },
  { label: "SHORE DIVE", value: "SHOREDIVE", image: "images/icon_divesite_shoredive.png" },
  //{ label: "CAVE",       value: "CAVE", image: "images/icon_divesite_cave2.png" },
  //{ label: "PIT DIVING", value: "PIT DIVING", image: "images/icon_divesite_pitdiving.png" },
];

const BUTTONS_SITEDEPTH = [
  { label: "ALL DEPTH", depthUp: "",    depthDown: ""    },
  { label: "6m",        depthUp: "",    depthDown: "-6"  },
  { label: "12m",       depthUp: "-6",  depthDown: "-12" },
  { label: "20m",       depthUp: "-12",  depthDown: "-20" },
  { label: "40m",       depthUp: "-20", depthDown: "-40" },
  { label: "60m",       depthUp: "-40", depthDown: "-60" },
  { label: "100m", depthUp: "-60", depthDown: "-100" },
  { label: "200m", depthUp: "-100", depthDown: "-200" },
  { label: "Deeper", depthUp: "-200", depthDown: "-100000" },
];

const CONST_SITECHECKSTATUS = [
  { label: "ALL",     value:0, api_id: "" },  
  { label: "Verified",   value:1, api_id: 1 },  
  { label: "Unverified", value:2, api_id: 0 },
  { label: "Doubtful",   value:3, api_id: 3 },  
  //{ label: "Rejected",   value:4, api_id: 2 },     
];


function TabPageOfDiveSites({ getmap, show, ...props }) {

  const [selectedDiveSiteType, setSelectedDiveSiteType] = useState(BUTTONS_SITETYPES[0]);
  const [selectedDiveSiteDepth, setSelectedDiveSiteDepth] = useState(0);
  const [selectedDiveSiteCheckStatus, setSelectedDiveSiteCheckStatus] = useState(CONST_SITECHECKSTATUS[0]);

  
  useEffect(() => {
    //console.log(selectedDiveSiteDepth);
    const sites_layer = getmap().getLayers().getArray().filter((layer) => layer.get("title") === "Sites")[0];

    setSelectedDiveSiteType(sites_layer.get("ShowDiveSiteType"));

    const olDepth = sites_layer.get("ShowDiveSiteDepthDown");   
    BUTTONS_SITEDEPTH.map((tabDepth, i) => {
      if (olDepth === tabDepth.depthDown) {
        //console.log(i);
        setSelectedDiveSiteDepth(i);
      }
    });
    
  }, []); 

  const onClickSiteType = (sitetype) => {
    if (sitetype === selectedDiveSiteType)
      sitetype = ""; // hide layer

    setSelectedDiveSiteType(sitetype);

    let sites_layer = getmap().getLayers().getArray().filter((layer) => layer.get("title") === "Sites")[0];
    sites_layer.set("ShowDiveSiteType", sitetype);
    sites_layer.getSource().changed();
    console.log(sitetype);
  };

  const onClickSiteDepth = (id_sitedepth) => {
    console.log(selectedDiveSiteDepth);
    setSelectedDiveSiteDepth(id_sitedepth);
    let sites_layer = getmap().getLayers().getArray().filter((layer) => layer.get("title") === "Sites")[0];
    sites_layer.set("ShowDiveSiteDepthUp", BUTTONS_SITEDEPTH[id_sitedepth].depthUp);
    sites_layer.set("ShowDiveSiteDepthDown", BUTTONS_SITEDEPTH[id_sitedepth].depthDown);
    sites_layer.getSource().changed();
  }; 

  const handleChangeSiteCheckStatus = (event) => {
    setSelectedDiveSiteCheckStatus(CONST_SITECHECKSTATUS[event.target.value]);
    let sites_layer = getmap().getLayers().getArray().filter((layer) => layer.get("title") === "Sites")[0];
    sites_layer.set("ShowDiveSiteCheckStatus", CONST_SITECHECKSTATUS[event.target.value].api_id);
    sites_layer.getSource().changed();    
  };  
 
  return (
    <div hidden={!show}>

    <center>
      <ButtonGroup orientation="vertical" aria-label="Vertical button group" variant="text">
         {BUTTONS_SITEDEPTH.map((button, i) => {
            if (i === selectedDiveSiteDepth)
              return <Button key={i} onClick={() => onClickSiteDepth(i)} variant="contained" color="warning">{button.label}</Button>
            else
              return <Button key={i} onClick={() => onClickSiteDepth(i)}>{button.label}</Button>
          })}
      </ButtonGroup>
    </center>  

    <ImageList cols={1}>
      {BUTTONS_SITETYPES.map((tab_item, i) => (
        <ButtonImage
          key={`${tab_item.label}_${i}`} //{i * 10}
          isSelected={tab_item.label === selectedDiveSiteType}
          exclusive={true}
          title={tab_item.label}
          image={tab_item.image}
          btn_width="90"
          btn_height="60"
          handleClick={onClickSiteType}
        />
      ))}
    </ImageList>

    <FormControl fullWidth size="small" >
        <InputLabel id="demo-simple-select-label">Sites status</InputLabel>
        <Select
          //labelId="demo-simple-select-label"
          //id="demo-simple-select"
          value={selectedDiveSiteCheckStatus.value}
          label="Sites status"
          onChange={handleChangeSiteCheckStatus}
          displayEmpty
          inputProps={{ 'aria-label': 'Without label' }}
        >
          {CONST_SITECHECKSTATUS.map( (item) => {            
             return <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>
          }) }
          
        </Select>
      </FormControl>    
       
    </div>
  );
}

export default TabPageOfDiveSites;
