import TileLayer from "ol/layer/Tile";
import TileWMS from "ol/source/TileWMS";
import LayerGroup from 'ol/layer/Group.js';

import WMTS from 'ol/source/WMTS.js';
import WMTSTileGrid from 'ol/tilegrid/WMTS.js';
import {get as getProjection} from 'ol/proj.js';
import {getTopLeft, getWidth} from 'ol/extent.js';

import { CMEMS_createLayer } from './CMEMSFunctions.js'
import { getUTCDateEarlierRounded } from './DatetimesFunctions.js';



// https://view.eumetsat.int/geoserver/wms?service=WMS&version=1.3.0&request=GetCapabilities


// Definition of Map background layers
// zindex : 0 = behind (ie image), 10 = intermediate, 100 = top
export default function createLayersGroupWeather(categoryId="weather") {
  
    const dt_now = new Date();
    const dateTime = dt_now.toISOString(); //'2024-05-09T00:00:00Z';
    //console.log(dateTime);      

    /*
    const dateMinus30Minutes = new Date(dt_now);  // Créer une copie de la date courante
    dateMinus30Minutes.setUTCMinutes(dt_now.getUTCMinutes() - 30);  
    const str_dateMinus30Minutes = dateMinus30Minutes.toISOString(); 
    //dt_now.setUTCHours(0,0,0); */
  
    /*
    const roundToPreviousQuarterHourUTC = (date) => {
      const minutes = date.getUTCMinutes();
    
      // Calcul pour arrondir au quart d'heure inférieur
      const roundedMinutes = Math.floor(minutes / 15) * 15;
    
      // Mettre à jour les minutes en UTC avec les minutes arrondies
      date.setUTCMinutes(roundedMinutes);
      date.setUTCSeconds(0);  // Remettre les secondes à 0 pour un arrondi propre
      date.setUTCMilliseconds(0);  // Idem pour les millisecondes
    
      return date;
    };
    
    const getUTCDate30MinutesEarlierRounded = () => {
      const currentDateUTC = new Date();  // Date et heure actuelles en UTC
    
      // Soustraire 30 minutes de l'heure UTC actuelle
      currentDateUTC.setUTCMinutes(currentDateUTC.getUTCMinutes() - 30);
    
      // Arrondir au quart d'heure inférieur en UTC
      return roundToPreviousQuarterHourUTC(currentDateUTC);
    }; */

    const str_dateMinus30Minutes = getUTCDateEarlierRounded(0,30, 15).toISOString();; //getUTCDateMinutesEarlierRounded(30).toISOString();
    const str_threeHoursEarlier = getUTCDateEarlierRounded(3,0, 60).toISOString();; //getUTCDateHoursEarlierRounded(3).toISOString();
    

    // ------------------------------------------------------------------------------------------------------------
    //                                          EUMETSAT
    // ------------------------------------------------------------------------------------------------------------

    const layer_EUMETSAT_rgb = new TileLayer({
      title: "Cloud (Europe/Africa)",
      layerType: categoryId,
      visible: false,
      zIndex: 10,
      opacity: 0.7,
      layer_icon: "images/icon_IsoDepth.png",
      source: new TileWMS({
        url: "https://view.eumetsat.int/geoserver/wms",
        //https://view.eumetsat.int/geoserver/ows?service=WMS&request=GetMap&version=1.3.0&layers=mtg_fd:rgb_geocolour&styles=&format=image/jpeg&crs=EPSG:4326&bbox=-77.3506393432617,-81.2777938842773,77.3563919067383,81.2807235717773&width=800&height=761        
        params: { LAYERS: "mtg_fd:rgb_geocolour", TIME:str_dateMinus30Minutes, TILED: true },  
        transition: 0,
      }),
      
      layer_datetime: str_dateMinus30Minutes
    }); 

    const layer_EUMETSAT_rain1 = new TileLayer({
      title: "Rain (Europe/Africa)",        
      layerType: categoryId,
      visible: false,
      zIndex: 10,
      opacity: 0.75,
      layer_icon: "images/icon_IsoDepth.png",
      source: new TileWMS({
        url: "https://view.eumetsat.int/geoserver/wms",
        //https://view.eumetsat.int/geoserver/ows?service=WMS&request=GetMap&version=1.3.0&layers=mtg_fd:rgb_geocolour&styles=&format=image/jpeg&crs=EPSG:4326&bbox=-77.3506393432617,-81.2777938842773,77.3563919067383,81.2807235717773&width=800&height=761        
        params: { LAYERS: "msg_fes:h60b", TIME:str_dateMinus30Minutes, TILED: true },  
        transition: 0,
      }),    

      legend_description: "Blended SEVIRI / LEO MW precipitation and morphologic information - MSG - 0 degree. \n Instantaneous precipitation maps generated combining geostationary (GEO) IR images from operational geostationary satellites 'calibrated' by precipitation measurements from MW images on Low Earth Orbit (LEO) satellites, processed soon after each acquisition of a new image from GEO. The blending algorithm ('Rapid Update’) generates precipitation estimates combining the equivalent blackbody temperatures (TBB) at 10.8 μm with rain rates from all available Passive MW measurements. A separate treatment is performed for convective precipitation: the morphologic information and the enhancement of precipitation estimate is done by the use of NEFODINA software.",
      legend_url_graphic: 'https://view.eumetsat.int/geoserver/ows?service=WMS&version=1.3.0&request=GetLegendGraphic&format=image%2Fpng&width=640&height=80&layer=msg_fes%3Ah60b',
      legend_url_info: 'https://data.eumetsat.int/product/EO:EUM:DAT:0620',
      layer_datetime: str_dateMinus30Minutes
    });     
        
    const layer_EUMETSAT_cloud = new TileLayer({
      title: "Cloud black&white (world from EUMETSAT)",        
      layerType: categoryId,
      visible: false,
      zIndex: 10,
      opacity: 0.7,
      layer_icon: "images/icon_IsoDepth.png",
      source: new TileWMS({
        url: "https://view.eumetsat.int/geoserver/mumi/worldcloudmap_ir108/ows", //"https://view.eumetsat.int/geoserver/wms",
        params: { LAYERS: "worldcloudmap_ir108", TIME:str_threeHoursEarlier, TILED: true },   // "mumi:worldcloudmap_ir108"
        transition: 0,
      }),

      layer_datetime: str_threeHoursEarlier
    });  
     

    // ------------------------------------------------------------------------------------------------------------
    //                                          NOAA layers
    // ------------------------------------------------------------------------------------------------------------

    /*
    const layer_mf_cloud = new TileLayer({
      title: "Cloudiness (MF)",        
      layerType: categoryId,
      visible: false,
      zIndex: 10,
      //opacity: 0.6,
      layer_icon: "images/icon_IsoDepth.png",
      source: new TileWMS({
        url: "https://public-api.meteofrance.fr/public/arpege/1.0/wcs/MF-NWP-GLOBAL-ARPEGE-025-GLOBE-WCS/__OGC_REQUEST__?service=WCS&version=2.0.1",
        params: { LAYERS: "TOTAL_CLOUD_COVER__GROUND_OR_WATER_SURFACE___202401021200", TILED: true },  
        transition: 0,
      }),    
    });  */


    const layerSatIRGlobalNOAA = new TileLayer({
      title: "Cloud black&white (world from NOAA)",
      layerType: categoryId,
      visible: false,
      zIndex: 10,
      opacity: 0.5,
      layer_icon: "images/icon_IsoDepth.png",
      source: new TileWMS({
        url: "https://nowcoast.noaa.gov/geoserver/observations/satellite/ows", //"https://mapservices.weather.noaa.gov/raster/services/obs/mrms_qpe/ImageServer/WMSServer?request=GetCapabilities&service=WMS",
        params: { LAYERS: "global_longwave_imagery_mosaic" },  // global_visible_imagery_mosaic global_longwave_imagery_mosaic
        transition: 0,
      }),    
    });   
    
    /*
    const layerSatVisiGlobalNOAA = new TileLayer({
      title: "Satellite (visible)",
      layerType: categoryId,
      visible: false,
      zIndex: 10,
      opacity: 0.5,
      layer_icon: "images/icon_IsoDepth.png",
      source: new TileWMS({
        url: "https://nowcoast.noaa.gov/geoserver/observations/satellite/ows", //"https://mapservices.weather.noaa.gov/raster/services/obs/mrms_qpe/ImageServer/WMSServer?request=GetCapabilities&service=WMS",
        params: { LAYERS: "global_visible_imagery_mosaic" },  // global_visible_imagery_mosaic global_longwave_imagery_mosaic
        transition: 0,
      }),    
    });  */   

    // ------------------------------------------------------------------------------------------------------------
    //                                          IBL
    // ------------------------------------------------------------------------------------------------------------
    const layerWindGFSIBL = new TileLayer({
      title: "Wind",
      layerType: categoryId,
      visible: false,
      zIndex: 10,
      opacity: 1,
      layer_icon: "images/icon_IsoDepth.png",
      source: new TileWMS({
        url: "https://ogcie.iblsoft.com/ncep/gfs", //"https://mapservices.weather.noaa.gov/raster/services/obs/mrms_qpe/ImageServer/WMSServer?request=GetCapabilities&service=WMS",
        params: { LAYERS: "wind", STYLES: "black-red-gusts" },  // global_visible_imagery_mosaic global_longwave_imagery_mosaic
        transition: 0,
      }),    
    });    

    const layerPrecipGFSIBL = new TileLayer({
      title: "Precipitations",
      layerType: categoryId,
      visible: false,
      zIndex: 10,
      opacity: 0.6,
      layer_icon: "images/icon_IsoDepth.png",
      source: new TileWMS({
        url: "https://ogcie.iblsoft.com/ncep/gfs", //"https://mapservices.weather.noaa.gov/raster/services/obs/mrms_qpe/ImageServer/WMSServer?request=GetCapabilities&service=WMS",
        params: { LAYERS: "precipitation-6h", STYLE: "" },  // global_visible_imagery_mosaic global_longwave_imagery_mosaic
        transition: 0,
      }),    
    });      
    
    const layerTempGFSIBL = new TileLayer({
      title: "Air Temperature",
      layerType: categoryId,
      visible: false,
      zIndex: 10,
      opacity: 0.6,
      layer_icon: "images/icon_IsoDepth.png",
      source: new TileWMS({
        url: "https://ogcie.iblsoft.com/ncep/gfs", //"https://mapservices.weather.noaa.gov/raster/services/obs/mrms_qpe/ImageServer/WMSServer?request=GetCapabilities&service=WMS",
        params: { LAYERS: "temperature" },  // global_visible_imagery_mosaic global_longwave_imagery_mosaic
        transition: 0,
      }),    
    });  
    


    // ------------------------------------------------------------------------------------------------------------
    //                                          COPERNICUS layers
    // ------------------------------------------------------------------------------------------------------------

    // wind from ASCAT (yesterday)
    var dt_now2 = new Date();
    dt_now2.setDate(dt_now.getDate() - 1);  //dt_now.setHours(0,0,0);
    const datetime_yest = dt_now2.toISOString(); //'2024-05-09T00:00:00Z'; console.log(dateTime); 
    
    const layerWindCMEMSGlobal = CMEMS_createLayer(
      "Wind (yesterday)", categoryId, 10, 0.7,

      "WIND_GLO_PHY_L4_NRT_012_004/cmems_obs-wind_glo_phy_nrt_l4_0.125deg_PT1H_202207",      
      "wind", datetime_yest, "cmap:speed,vectorStyle:solidAndVector", 

      'images/icon_layer_waveheight_90x60_2.png', 
      'Wind (yesterday, 10 meter above the surface for wind)',
      '',
      ''
    ); 
         
       
       

  return [    
    layer_EUMETSAT_rgb,
    layer_EUMETSAT_rain1,
    layer_EUMETSAT_cloud,
    layerSatIRGlobalNOAA,      
    //layerSatVisiGlobalNOAA,    
    layerWindCMEMSGlobal,
    

    //layerWindGFSIBL,
    //layerPrecipGFSIBL,
    //layerTempGFSIBL,
  ];
}
