import TileLayer from "ol/layer/Tile";
import LayerGroup from "ol/layer/Group.js";

import WMTS from "ol/source/WMTS.js";
import WMTSTileGrid from "ol/tilegrid/WMTS.js";
import { get as getProjection } from "ol/proj.js";
import { getTopLeft, getWidth } from "ol/extent.js";

const projection = getProjection("EPSG:3857");
const projectionExtent = projection.getExtent();
const size = getWidth(projectionExtent) / 256;

const resolutions = new Array(10);
const matrixIds = new Array(10);
for (let z = 0; z < 10; ++z) {
  // generate resolutions and matrixIds arrays for this WMTS
  resolutions[z] = size / Math.pow(2, z);
  matrixIds[z] = z;
}

const URL_CMEMS_WMTS = "https://wmts.marine.copernicus.eu/teroWmts";


// Example: 
//   CMEMS_createChildLayer("Sea temperature", "GLOBAL_ANALYSISFORECAST_PHY_001_024/XXX",  "thetao", dateTime, style, 1000)
export function CMEMS_createChildLayer(title, dataId, paramId, datetime, style, maxResolution = undefined) {
  return new TileLayer({
    title: title,
    //layerType: categoryId,
    maxResolution: maxResolution,
    //visible: false,
    //zIndex: zIndex,
    //opacity: 0.6,
    //layer_icon: "images/icon_layer_seatemp_90x60.png",
    source: new WMTS({
      url: URL_CMEMS_WMTS,
      layer: dataId + "/" + paramId,
      matrixSet: "EPSG:3857",
      format: "image/png",
      dimensions: { time: datetime },
      style: style,
      tileGrid: new WMTSTileGrid({
        origin: getTopLeft(projectionExtent),
        resolutions: resolutions,
        matrixIds: matrixIds,
      }),
    }),
  });
}

// Example
//  CMEMS_createLayer("Wave height", categoryId, 10, "images/icon.png", "GLOBAL_ANALYSISFORECAST/XXX", "VHM0", dateTime, "cmap:rainbow", 'Wave height (MFWAM)', '',  '')  
export function CMEMS_createLayer(title, categoryId, zIndex, opacity, 
                                  dataId, paramId, 
                                  datetime = undefined, style = undefined, 
                                  layer_icon, legend_description, legend_url_graphic, legend_url_info) {
  return new TileLayer({
    title: title,
    layerType: categoryId,
    visible: false,
    zIndex: zIndex,
    opacity: opacity, //0.7,
    layer_icon: layer_icon,

    legend_description: legend_description,
    legend_url_graphic: legend_url_graphic,
    legend_url_info: legend_url_info,
    layer_datetime: datetime,

    source: new WMTS({
      url: URL_CMEMS_WMTS,
      layer: dataId + "/" + paramId,
      matrixSet: "EPSG:3857",
      format: "image/png",
      dimensions: { time: datetime },
      style: style,
      tileGrid: new WMTSTileGrid({
        origin: getTopLeft(projectionExtent),
        resolutions: resolutions,
        matrixIds: matrixIds,
      }),
    }),
  });
}

export function CMEMS_createGroupLayer(title, categoryId, zIndex, opacity, 
                                       layersTab, 
                                       datetime, 
                                       layer_icon, legend_description, legend_url_graphic, legend_url_info) {
  // group layer
  return new LayerGroup({
    title: title,
    layerType: categoryId,
    visible: false,
    zIndex: zIndex,
    opacity: opacity, //1,
    layer_icon: layer_icon,

    layers: layersTab,

    legend_description: legend_description,
    legend_url_graphic: legend_url_graphic,
    legend_url_info: legend_url_info,    
    layer_datetime: datetime,
  });
}


export function CMEMS_createGroupLayerFromParams(title, categoryId, zIndex, opacity, 
                                                 layersTabParam, 
                                                 datetime, style, 
                                                 layer_icon, legend_description, legend_url_graphic, legend_url_info) {
  const tabLayers = layersTabParam.map( (layer) => { return CMEMS_createChildLayer(title+' '+layer.dataset+'/'+layer.param, layer.dataset, layer.param, datetime, style) } );
  console.log(tabLayers);
  return CMEMS_createGroupLayer(title, categoryId, zIndex, opacity, tabLayers, datetime, layer_icon, legend_description, legend_url_graphic, legend_url_info);
}