import React, { useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';

import { styled, alpha } from '@mui/material/styles';

import { fromLonLat } from "ol/proj.js";

import ShowListIcon from "@mui/icons-material/FormatListBulleted";
import AccountIcon from "@mui/icons-material/SentimentSatisfiedAlt";
import HomeIcon from "@mui/icons-material/Home";

import AppBarSearch from './AppBarSearch';


function AppBarGD({ getmap }) { 

  function handleClickHome() {
      const userLat = getmap().get('userLat');      
      const userLon = getmap().get('userLon');
      const animDef = { center: fromLonLat([userLon, userLat]), zoom: 7, rotation:0, duration:0 }; 
      //console.log('userLat', userLat, 'userLon', userLon);
      getmap().getView().animate(animDef);       
  }

  return (
    <AppBar position="fixed" style={{ top:"0px", color:'black', boxShadow: 'none', backgroundColor: alpha('#A9A9A9', 0.5)}} > {/* background: 'transparent', */}
    <Toolbar >
      {/* Show site list Button */}
      <IconButton aria-label="show list" size="medium" style={{ backgroundColor: "#eeeeee" }} >
        <ShowListIcon />
      </IconButton>

       <AppBarSearch getmap={getmap} />

       <IconButton aria-label="show list" size="medium" onClick={handleClickHome} style={{ backgroundColor: "#eeeeee" }} >
        <HomeIcon />
      </IconButton>
      
      <Box sx={{ flexGrow: 1 }} />

      {/* Account Button */}                                                
      <IconButton aria-label="account" size="medium" style={{ backgroundColor:"#eeeeee" }} >
        <AccountIcon />
      </IconButton> 

    </Toolbar>
  </AppBar>
  );
}

export default AppBarGD;