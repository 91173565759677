import TileLayer from "ol/layer/Tile";
import TileWMS from "ol/source/TileWMS";
import LayerGroup from 'ol/layer/Group.js';


// Ressources
//    https://geo.vliz.be/geoserver/Emodnetbio/wms?SERVICE=WMS&request=GetCapabilities
//    https://ows.emodnet-seabedhabitats.eu/geoserver/emodnet_view_maplibrary/wms?SERVICE=WMS&request=GetCapabilities
//
// Infos produits
//    https://emodnet.ec.europa.eu/en/seabed-habitats
//    https://emodnet.ec.europa.eu/en/emodnet-data-layers-catalogue-within-european-atlas-seas#SeabedHabitats



// Definition of Map background layers
// zindex : 0 = behind (ie image), 10 = intermediate, 100 = top
export default function createLayersGroupBio(categoryId="bio") {
  
 
    // ------------------------------------------------------------------------------------------------------------
    //                                          Misc layers
    // ------------------------------------------------------------------------------------------------------------
  
    /*
    // same as next
    const layerEMODNET_CoralMaerl = new TileLayer({
      title: "Maërl grid (Mediterranean)",
      layerType: categoryId,
      visible: false,
      zIndex: 10,
      //opacity: 0.6,
      layer_icon: "images/icon_IsoDepth.png",
      source: new TileWMS({
        //url: "https://geo.vliz.be/geoserver/Emodnetbio/wms",
        url: "https://ows.emodnet-seabedhabitats.eu/geoserver/emodnet_view_maplibrary/wms",
        params: { LAYERS: "mediseh_maerl" },   //  mediseh_maerl_model
        transition: 0,
      }), 

      legend_description: 'EMODnet Modelled Spatial Distributions of Maërl Habitats (2014). This dataset shows modelled spatial distributions of coralligenous outcrops and maërl beds across the Mediterranean Sea. These bioconstructions are typical Mediterranean underwater seascapes, comprising coralline algal frameworks that grow in dim light conditions. They are the result of the building activities of algal and animal constructors, counterbalanced by physical, as well as biological, eroding processes. Because of their extent, biodiversity and production, coralligenous and maërl habitats rank among the most important ecosystems in the Mediterranean Sea, and they are considered of great significance both for fisheries and carbon regulation.',
      legend_url_graphic: 'http://geo.vliz.be/geoserver/Emodnetbio/ows?service=WMS&version=1.3.0&request=GetLegendGraphic&format=image%2Fpng&width=20&height=20&layer=mediseh_maerl_model',
      legend_url_info: 'https://emodnet.ec.europa.eu/en/map-week-posidonia-oceanica-distribution', // 'https://emodnet.ec.europa.eu/en/seabed-habitats',   https://www.iucnredlist.org/species/153534/135156882      
    }); */

    // same data but from geo.vliz.be : better because limited area are easyier to understand (rather than shading)
    const layerEMODNET_CoralMaerl2 = new TileLayer({
      title: "Maërl (Mediterranean)",
      layerType: categoryId,
      visible: false,
      zIndex: 10,
      //opacity: 0.6,
      layer_icon: "images/icon_IsoDepth.png",
      source: new TileWMS({
        url: "https://geo.vliz.be/geoserver/Emodnetbio/wms",
        params: { LAYERS: "mediseh_maerl_model" },  
        transition: 0,
      }), 

      legend_description: 'EMODnet Modelled Spatial Distributions of Maërl Habitats (2014). This dataset shows modelled spatial distributions of coralligenous outcrops and maërl beds across the Mediterranean Sea. These bioconstructions are typical Mediterranean underwater seascapes, comprising coralline algal frameworks that grow in dim light conditions. They are the result of the building activities of algal and animal constructors, counterbalanced by physical, as well as biological, eroding processes. Because of their extent, biodiversity and production, coralligenous and maërl habitats rank among the most important ecosystems in the Mediterranean Sea, and they are considered of great significance both for fisheries and carbon regulation.',
      legend_url_graphic: 'http://geo.vliz.be/geoserver/Emodnetbio/ows?service=WMS&version=1.3.0&request=GetLegendGraphic&format=image%2Fpng&width=20&height=20&layer=mediseh_maerl_model',
      legend_url_info: 'https://emodnet.ec.europa.eu/en/map-week-posidonia-oceanica-distribution', // 'https://emodnet.ec.europa.eu/en/seabed-habitats',   https://www.iucnredlist.org/species/153534/135156882      
    });     


    const layerEMODNET_Coral = new TileLayer({
      title: "Coral (Mediterranean)",
      layerType: categoryId,
      visible: false,
      zIndex: 10,
      //opacity: 0.6,
      layer_icon: "images/icon_IsoDepth.png",
      source: new TileWMS({
        url: "https://geo.vliz.be/geoserver/Emodnetbio/wms",
        params: { LAYERS: "mediseh_coral_model" },   // mediseh_cor_poly mediseh_coral_model
        transition: 0,
      }),
      
      legend_description: 'Coral habitats. EMODnet Modelled Spatial Distributions of Coralligenous Habitats (2014). This dataset shows modelled spatial distributions of coralligenous outcrops and maërl beds across the Mediterranean Sea. These bioconstructions are typical Mediterranean underwater seascapes, comprising coralline algal frameworks that grow in dim light conditions. They are the result of the building activities of algal and animal constructors, counterbalanced by physical, as well as biological, eroding processes. Because of their extent, biodiversity and production, coralligenous and maërl habitats rank among the most important ecosystems in the Mediterranean Sea, and they are considered of great significance both for fisheries and carbon regulation.',
      legend_url_graphic: 'http://geo.vliz.be/geoserver/Emodnetbio/ows?service=WMS&version=1.3.0&request=GetLegendGraphic&format=image%2Fpng&width=20&height=20&layer=mediseh_coral_model',
      legend_url_info: 'https://emodnet.ec.europa.eu/en/map-week-coral-coralligenous-habitats',   // 'https://emodnet.ec.europa.eu/en/seabed-habitats', 
    });    
    
    /* meme affichage que ci-dessous mais en niveau de proba
    const layerEMODNET_Posidonia2 = new TileLayer({
      title: "Posidonia 2 (Mediterranean)",
      layerType: categoryId,
      visible: false,
      zIndex: 10,
      //opacity: 0.6,
      layer_icon: "images/icon_IsoDepth.png",
      source: new TileWMS({
        url: "https://ows.emodnet-seabedhabitats.eu/geoserver/emodnet_view_maplibrary/wms",
        //url: "https://geo.vliz.be/geoserver/Emodnetbio/wms",
        params: { LAYERS: "mediseh_posidonia" },   // mediseh_posidonia_current_shape mediseh_posidonia_model mediseh_posidonia_historical_shape
        transition: 0,
      }), 
      
      legend_description: 'Distribution of Posidonia oceanica. EMODnet Modelled Posidonia oceanica Distribution (2013). This dataset is an output of the “Mediterranean Sensitive Habitats” project (MEDISEH). It shows under a raster form modelled spatial distributions of Posidonia oceanica across the Mediterranean Sea. Posidonia oceanica is endemic to the Mediterranean Sea, where it is the dominant seagrass, covering about 50,000 km2 of coastal to offshore sandy and rocky areas down to depths of about 45 m. P. oceanica is a protected species according to EU legislation (Habitat directive), the Bern and Barcelona Conventions and several national legislations. The raster has a spatial resolution of 0.004166 decimal degrees, and the values are in the [0,1] interval (occurrence probabilities).',
      //legend_url_graphic: 'http://geo.vliz.be/geoserver/Emodnetbio/ows?service=WMS&version=1.3.0&request=GetLegendGraphic&format=image%2Fpng&width=20&height=20&layer=mediseh_posidonia_model',
      legend_url_graphic: 'https://ows.emodnet-seabedhabitats.eu/geoserver/emodnet_view_maplibrary/ows?service=WMS&version=1.3.0&request=GetLegendGraphic&format=image%2Fpng&width=20&height=20&layer=mediseh_posidonia',      
      legend_url_info: 'https://emodnet.ec.europa.eu/en/map-week-posidonia-oceanica-distribution', // 'https://emodnet.ec.europa.eu/en/seabed-habitats',   https://www.iucnredlist.org/species/153534/135156882  https://emodnet.ec.europa.eu/en/emodnet-data-layers-catalogue-within-european-atlas-seas#SeabedHabitats

https://sextant.ifremer.fr/services/wms/dcsmm?SERVICE=WMS&VERSION=1.3.0&REQUEST=GetMap&FORMAT=image%2Fpng&TRANSPARENT=true&LAYERS=posidonies_med_dcsmm2018&STYLES=&CRS=EPSG%3A3857&WIDTH=1404&HEIGHT=822&BBOX=355688.05907092226%2C5342158.918492114%2C460601.0626689917%2C5403582.3436755985

    }); */    

    const layerEMODNET_Posidonia = new TileLayer({
      title: "Posidonia (Mediterranean)",
      layerType: categoryId,
      visible: false,
      zIndex: 10,
      opacity: 0.7,
      layer_icon: "images/icon_IsoDepth.png",
      source: new TileWMS({
        //url: "https://ows.emodnet-seabedhabitats.eu/geoserver/emodnet_view_maplibrary/wms",
        url: "https://geo.vliz.be/geoserver/Emodnetbio/wms",
        params: { LAYERS: "mediseh_posidonia_model" },   // mediseh_posidonia_current_shape mediseh_posidonia_model mediseh_posidonia_historical_shape
        transition: 0,
      }), 
      
      legend_description: 'Distribution of Posidonia oceanica. EMODnet Modelled Posidonia oceanica Distribution (2013). This dataset is an output of the “Mediterranean Sensitive Habitats” project (MEDISEH). It shows under a raster form modelled spatial distributions of Posidonia oceanica across the Mediterranean Sea. Posidonia oceanica is endemic to the Mediterranean Sea, where it is the dominant seagrass, covering about 50,000 km2 of coastal to offshore sandy and rocky areas down to depths of about 45 m. P. oceanica is a protected species according to EU legislation (Habitat directive), the Bern and Barcelona Conventions and several national legislations. The raster has a spatial resolution of 0.004166 decimal degrees, and the values are in the [0,1] interval (occurrence probabilities).',
      legend_url_graphic: 'http://geo.vliz.be/geoserver/Emodnetbio/ows?service=WMS&version=1.3.0&request=GetLegendGraphic&format=image%2Fpng&width=20&height=20&layer=mediseh_posidonia_model',
      //legend_url_graphic: 'https://ows.emodnet-seabedhabitats.eu/geoserver/emodnet_view_maplibrary/ows?service=WMS&version=1.3.0&request=GetLegendGraphic&format=image%2Fpng&width=20&height=20&layer=mediseh_posidonia',      
      legend_url_info: 'https://emodnet.ec.europa.eu/en/map-week-posidonia-oceanica-distribution', // 'https://emodnet.ec.europa.eu/en/seabed-habitats',   https://www.iucnredlist.org/species/153534/135156882  https://emodnet.ec.europa.eu/en/emodnet-data-layers-catalogue-within-european-atlas-seas#SeabedHabitats

    });      


    const layerEMODNET_PosidoniaCurrentShape = new TileLayer({
      title: "Posidonia zones (Mediterranean)",
      layerType: categoryId,
      visible: false,
      zIndex: 10,
      //opacity: 0.6,
      layer_icon: "images/icon_IsoDepth.png",
      source: new TileWMS({
        //url: "https://ows.emodnet-seabedhabitats.eu/geoserver/emodnet_view_maplibrary/wms",
        url: "https://geo.vliz.be/geoserver/Emodnetbio/wms",
        params: { LAYERS: "mediseh_posidonia_current_shape" },   // mediseh_posidonia_current_shape mediseh_posidonia_model mediseh_posidonia_historical_shape
        //projection: 'EPSG:4326',
        transition: 0,
      }),    

      legend_description: 'EMODnet Posidonia oceanica meadows distribution - Current Distribution (polygons). Current Posidonia oceanica meadows distribution in the Mediterranean Sea. One of the general objectives of the MEDISEH (Mediterranean Sensitive Habitats) project was to compile historical and current data on seagrass beds. Belluscio A, Panayiotidis P, Gristina M., Knittweis L., Pace M.L.,Telesca L, Criscoli A, Apostolaki ET, Gerakaris V., S. Fraschetti, M. T. Spedicato, G. Lembo, M. Salomidi,R. Mifsud, G. Fabi, F. Badalamenti, G. Garofalo A. Alagna, Ardizzone G.D., Martin C., V. Valavanis 2013. Seagrass beds distribution along the Mediterranean coasts. Mediterranean Sensitive Habitats (MEDISEH) Final Report, DG MARE Specific Contract SI2.600741.',
      legend_url_graphic: 'http://geo.vliz.be/geoserver/Emodnetbio/ows?service=WMS&version=1.3.0&request=GetLegendGraphic&format=image%2Fpng&width=20&height=20&layer=mediseh_posidonia_current_shape',
      legend_url_info: 'https://emodnet.ec.europa.eu/en/map-week-posidonia-oceanica-distribution', // 'https://emodnet.ec.europa.eu/en/seabed-habitats',   https://www.iucnredlist.org/species/153534/135156882

    });  


    /*
    const layerSEXTANT_Posidonia = new TileLayer({
      title: "Posidonia loc 2018 (Mediterranean)",
      layerType: categoryId,
      visible: false,
      zIndex: 10,
      //opacity: 0.6,
      layer_icon: "images/icon_IsoDepth.png",
      source: new TileWMS({
        url: "https://sextant.ifremer.fr/services/wms/dcsmm",        
        params: { LAYERS: "posidonies_med_dcsmm2018" },   
        transition: 0,
      }), 
      
      legend_description: '',
      legend_url_graphic: '',      
      legend_url_info: '', 
    }); */

    // https://newredlist-geo.iucnredlist.org/geoserver/ows?&service=WMS&request=GetMap&layers=redlist%3Aassessment_ranges_by_assessment_id&styles=&format=image%2Fpng&transparent=true&version=1.3.0&tiled=true&viewparams=assessment_id%3A135156882&width=256&height=256&crs=EPSG%3A3857&bbox=352221.8263380922,5146352.240384347,371789.7055790973,5165920.119625352
    // https://newredlist-geo.iucnredlist.org/geoserver/ows?REQUEST=GetMap&SERVICE=WMS&VERSION=1.3.0&FORMAT=image%2Fpng&STYLES=&TRANSPARENT=true&LAYERS=redlist%3Aassessment_points_by_assessment_id&VIEWPARAMS=assessment_id%3A135156882&WIDTH=256&HEIGHT=256&CRS=EPSG%3A3857&BBOX=626172.1357121654%2C4852834.05176927%2C782715.1696402064%2C5009377.085697311

    const layerIUCN_Posidonia = new TileLayer({
      title: "Posidonia hist (IUCN)",
      layerType: categoryId,
      visible: false,
      zIndex: 10,
      opacity: 0.7,
      layer_icon: "images/icon_IsoDepth.png",
      source: new TileWMS({        
        url: "https://newredlist-geo.iucnredlist.org/geoserver/ows",
        params: { LAYERS: "redlist:assessment_points_by_assessment_id", VIEWPARAMS: "assessment_id:135156882" },
        transition: 0,
      }), 
      
      legend_description: 'IUCN 2024. IUCN Red List of Threatened Species. Version 2024-1 <www.iucnredlist.org>',
      legend_url_graphic: 'https://newredlist-geo.iucnredlist.org/geoserver/ows?service=WMS&request=GetLegendGraphic&format=image%2Fpng&width=20&height=20&layer=redlist%3Aassessment_points_by_assessment_id&style=V5',      
      legend_url_info: '', 

    });     

        
    // peu d'interet
    const layerEMODNET_invasive_macroalgae = new TileLayer({
      title: "Microalgs",
      layerType: categoryId,
      visible: false,
      zIndex: 10,
      //opacity: 0.6,
      layer_icon: "images/icon_IsoDepth.png",
      source: new TileWMS({
        url: "https://geo.vliz.be/geoserver/Emodnetbio/wms",
        params: { LAYERS: "kuenm_models" },   // kuenm_models chli_19582016_L1_err
        transition: 0,
      }),    
    });   
    
    
    const layerCoral = new TileLayer({
      title: "Coral",
      layerType: categoryId,
      visible: false,
      zIndex: 10,
      //opacity: 0.6,
      layer_icon: "images/icon_IsoDepth.png",
      source: new TileWMS({
        url: "https://allencoralatlas.org/geoserver/ows/wms",
        params: { LAYERS: "coral-atlas:benthic_data_verbose" }, 
        //projection: 'EPSG:4326',
        transition: 0,
      }),    
    });     


       
  return [    
    layerEMODNET_Coral,   
    layerEMODNET_CoralMaerl2,    
    layerEMODNET_Posidonia,
    layerEMODNET_PosidoniaCurrentShape, 
    //layerIUCN_Posidonia,
  ];
}
