import React from "react";
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
//import Typography from "@mui/material/Typography";
import Accordion from '@mui/material/Accordion';
//import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
//import CloseIcon from '@mui/icons-material/Close';
import ArrowDropDownIcon from '@mui/icons-material/ChevronRight'; // Dns // ExpandMore // Topic
//import GotoAreaIcon from '@mui/icons-material/GpsFixed';

import { fromLonLat } from "ol/proj.js";
import {boundingExtent } from 'ol/extent';


const AREA_WORLD = [
  { label:"Worldmap", items:[   // Planisphere
    { label: "Euro Afri",      anim:{center:fromLonLat([0, 15]), zoom: 1, rotation:0, duration:0} },
    { label: "Asia",           anim:{center:fromLonLat([109, 15]), zoom: 1, rotation:0, duration:0} },
    { label: "Pacific",        anim:{center:fromLonLat([210, 15]), zoom: 1, rotation:0, duration:0} },
    { label: "America",        anim:{center:fromLonLat([-70, 15]), zoom: 1, rotation:0, duration:0} }
   
  ]},
  { label: "Europe", items:[ 
    { label: "France",            extent:[-6.40812,51.50547, 11.11778,40.5], subitems:[      
      { label: "Iles d'Or",      extent:[6.13690,43.07114 , 6.55594,42.96965] },    
      { label: "Marseille",      extent:[5.19839,43.32562 , 5.42350,43.15641] },    
      { label: "Corsica",        extent:[8.42567,43.15411 , 9.71211,41.24783] },  
      { label: "Côte Vermeille", extent:[3.05533,42.53527 , 3.18778,42.40914] }
    ]},
    { label: "Spain",             extent:[-10.36126,44.0866, 6.54733,34.827438], subitems:[  
      { label: "Medes",          extent:[3.21836,42.05268, 3.23010,42.03878] },  
      { label: "Baleares",       extent:[2.04709,40.30744, 4.92112,38.84388] },  
      { label: "Cap Creus",      extent:[3.17090,42.35978, 3.34520,42.21638] }
    ]},
    { label: "Malta",             extent:[14.11528,36.09297, 14.62460,35.77633] },              
    { label: "Canary",            extent:[-18.46235,29.63409, -12.62254,27.44206] },       
    { label: "Madeira",           extent:[-17.38856,33.25525,-16.08285,32.30868] },              
    { label: "Azores",           extent:[-31.86126,40.15998,-24.24223,36.40423] }
  ]},              
  { label: "Africa", items:[
    { label: "Egypt",             extent:[24.65604,32.59592 , 37.42270,21.98564], subitems:[  
      { label: "Safaga",         extent:[33.89926,26.86343 , 34.16226,26.5361] }
    ]}
  ]},
  { label: "Asia", items:[
     { label: "Indonesia",      extent:[93.24616,7.18384, 143.29275,-11.55007 ], subitems:[
      { label: "Bali",           extent:[114.36842,-7.98685 , 115.81204,-8.92832] },
      { label: "Komodo",         extent:[119.23571,-8.35073 , 119.99048,-8.86432] }
    ]}, 
  ]}   
];


function TabPageOfArea({ getmap, show, ...props }) {
  const [openMenuId, setOpenMenuId] = React.useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleOpenMenu = (areaId, event) => {
    setAnchorEl(event.currentTarget);
    setOpenMenuId(areaId);
    //onClickArea(areaGroup);    
  };

  const handleClickSubItem = (areaGroup) => {
    onClickArea(areaGroup);
    handleClose();
    //setTimeout(handleClose, 5000);
  };  

  const handleClose = () => {
    setOpenMenuId(null);
  };  

  const onClickArea = (area) => {    
    //event.stopPropagation();
    const map = getmap();
    if (area.anim) {
        let animDef =  area.anim; //{ rotation:0, zoom: 1 } // duration: 0 center: fromLonLat([0, 0]),    
        //animDef.duration = 0;
        map.getView().animate(animDef);
    } else if (area.extent) {        
        const topleft = fromLonLat( [ area.extent[0], area.extent[1] ]);
        const bottomright = fromLonLat( [ area.extent[2], area.extent[3] ] );
        const extentDef = boundingExtent([topleft,bottomright]);
        //const extentDef = transformExtent(boundingExtent([topleft,bottomright]), get('EPSG:4326'), get('EPSG:3857'));

        //console.log(map.getView().calculateExtent());
        map.getView().fit(extentDef);
    }
    
  };

  const renderItem = (area,areaIndex, groupIndex) => {
    const itemMenuId = groupIndex*100+areaIndex; //area.label+"_"+areaIndex.toString();
    //console.log(itemId);
    if (!("subitems" in area))
      return( 
        <Button key={itemMenuId} style={{ marginBottom:1,border:0, fontSize: "12px",paddingLeft:10, justifyContent: "flex-start"}}  onClick={() => onClickArea(area)}>{area.label}</Button> 
        )
    else
      return(  
        <div>
          <ButtonGroup variant="text">
              <Button size="small" key={itemMenuId} style={{ marginBottom:1, border:0, fontSize: "12px", paddingLeft:10,width:"70px", justifyContent: "flex-start"}} onClick={() => onClickArea(area, areaIndex)}>{area.label} </Button>
              <Button size="small" style={{ marginBottom:1,border:0 }} onClick={(event) => handleOpenMenu(itemMenuId, event)}><ArrowDropDownIcon fontSize="small" /></Button>
            </ButtonGroup>  
          <Menu 
            //id={"menu_"+itemId}
            open={openMenuId === itemMenuId}
            anchorEl={anchorEl}
            onClose={handleClose}
          >                      
              {area.subitems.map((subArea) => {
                return( <MenuItem key={itemMenuId} onClick={ () => handleClickSubItem(subArea) } style={{color:"#1769aa" }}>{subArea.label}</MenuItem>);
              })}

            <Divider sx={{ my: 0.5 }} />
            <MenuItem onClick={handleClose} disableRipple>
              <small><b>Close</b></small>
            </MenuItem>  

          </Menu>
        </div> 
        
    );    
  }


  return (
    <div hidden={!show}>    
    {AREA_WORLD.map((areaGroup, groupId) => {      
      return(       
         <Accordion key={`${areaGroup}_${groupId}`} elevation={0} disableGutters={true}>
         <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id={groupId}                    
         >          
          {areaGroup.label}
         </AccordionSummary>                 

         <AccordionDetails key={`${areaGroup}_${groupId}`}>              
 
            <ButtonGroup orientation="vertical" aria-label="Vertical button group" variant="text">
              {areaGroup.items.map( (area, areaId) => renderItem(area, areaId, groupId))}
            </ButtonGroup>

        </AccordionDetails>
        </Accordion>         
     );
    })}
   
  </div>
  );
}

export default TabPageOfArea;
