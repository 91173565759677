import React, { useState, useEffect } from "react";
import Drawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Fab from "@mui/material/Fab";
//import Grid from '@mui/material/Grid';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import { fromLonLat, toLonLat, transform } from "ol/proj.js";

import ClosePanelIcon from "@mui/icons-material/ExpandMore";

import { apiGetDivesiteInfo, apiAdminEditDiveSite } from '../conf.js';


function convertToDMS(decimal, isLatitude) {
  const direction = decimal >= 0 
      ? (isLatitude ? 'N' : 'E') 
      : (isLatitude ? 'S' : 'W');
  
  const absDecimal = Math.abs(decimal);
  const degrees = Math.floor(absDecimal);
  const minutesDecimal = (absDecimal - degrees) * 60;
  const minutes = Math.floor(minutesDecimal);
  const seconds = ((minutesDecimal - minutes) * 60).toFixed(2);

  return `${degrees}° ${minutes}' ${seconds}" ${direction}`;
}



function TabPanel({ value, index, children }) {
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}        
      >
        {value === index && (
          <Box sx={{ p: 3 }}>                       
            {children}
          </Box>
        )}
      </div>
    );
  }


function PanelFeatureInfo({ map, panelVisible, feature, onClose }) {
    const [selectedPage, setSelectedPage] = React.useState(0);
    const [siteData, setSiteData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
      //setSiteData(null);
      setIsLoading(true);
      if (feature) {
        const id = feature ? feature.get('id') : undefined;
        fetch(`${apiGetDivesiteInfo}/?id=${id}`)
          .then((response) => response.json())
          .then((data) => { setSiteData(data); setIsLoading(false); })
          .catch((error) => console.error('Erreur de récupération des données:', error));
        //console.log(siteData);
      }
    }, [feature]);    

    function a11yProps(index) {
      return {
      id: `vertical-tab-${index}`,
      'aria-controls': `vertical-tabpanel-${index}`,
      };
    } 
  
    const handleChange = (event, newValue) => {
      setSelectedPage(newValue);
    };


    // display panel header : title and zoom buttons
    function renderHeader() {
        const txtName = feature ? feature.get('name') : 'Undefined';
        const txtDepth = feature ? feature.get('floor_depth')+'m' : '';

        return (
        <Paper
            square
            sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                border: "1px solid #ccc",
                padding: "10px"
            }}
        >
            <Box sx={{ marginLeft: "10px", marginRight: "20px", flexGrow: "1" }}>
            
                <Typography  variant="h5" align="center"  sx={{ marginBottom:"5px"}}>{txtName} <small><b>{txtDepth}</b></small></Typography>
                <center>         
                    <Button size="small" variant="outlined" sx={{ marginRight: "10px"}} onClick={() => ZoomAndCenterSite(0.4)}>Site</Button>  
                    <Button size="small" variant="outlined" sx={{ marginRight: "10px"}} onClick={() => ZoomAndCenterSite(2)}>Area</Button> 
                    <Button size="small" variant="outlined" sx={{ marginRight: "10px"}} onClick={() => ZoomAndCenterSite(24)}>Zone</Button>
                </center>
            </Box>
            
            <Fab size="small"  onClick={onClose} sx={{ width: "40px", hight:"40px" }}>
                <ClosePanelIcon />
            </Fab>

        </Paper>

        );
    }
  
    function renderSiteInfoLoaded()  {
      if (!isLoading) {      
        return (
          <>         
          <p><Typography> {siteData.description}</Typography></p>            
          <ul>
            {siteData.weblinks && siteData.weblinks.map((weblink, index) => (
            <li key={index}><a target="_blank" rel="noopener noreferrer" href={weblink.url_link}>{weblink.title}</a></li>
            ))}
          </ul>
          </>
        );
       } else { 
        return ( <p><Typography>Loading...</Typography></p> ); 
      }  
    }

    function renderSiteInfo() {       
      if (feature) {
        const id = feature.get('id');
        const txtDepth = feature ? feature.get('floor_depth')+'m' : '';
        let str_latlon = '';
        const coordinates = feature.getGeometry().getCoordinates();
        const [longitude, latitude] = transform(coordinates, 'EPSG:3857', 'EPSG:4326');
        //console.log('renderSiteInfo', longitude,latitude);
        const str_lat = convertToDMS(latitude, true);
        const str_lon = convertToDMS(longitude, false);
        str_latlon = str_lat + ' , ' + str_lon + "   (" + latitude.toFixed(5) + " , "+longitude.toFixed(5)+")";
      
        return (
          <>         
          <Typography>Depth <b>{txtDepth}</b></Typography>
          <Typography>Position <b>{str_latlon}</b></Typography>
          <a target="_blank" rel="noopener noreferrer" href={`${apiAdminEditDiveSite}/${id}/change`}>edit</a>

          { renderSiteInfoLoaded() } 
          </>
        );
      } else { return ( <Typography>No site selected</Typography> ); }
    }


    function ZoomAndCenterSite(resolution=null) {
        if (!map || !feature) return;
        const coord = feature.getGeometry().getCoordinates();  
        const map_resolution = resolution ? resolution : map.getView().getResolution();
        //const animDef = {center:fromLonLat([ toLonLat(coord) ])}; //, zoom: 1, rotation:0, duration:0};
        const new_coord = [ coord[0], coord[1] - map_resolution*(190-32) ]; // 190-72 : height of feature panel - top app bar
        const animDef = resolution ? {center:new_coord, resolution:resolution, duration:400} : {center:new_coord, duration:400};
        map.getView().animate(animDef);
    }    
    
   /*
    function handleCenterSite() {
        //const map = getMap();
        if (!map || !feature) return;
        const coord = feature.getGeometry().getCoordinates();  
        //const animDef = {center:fromLonLat([ toLonLat(coord) ])}; //, zoom: 1, rotation:0, duration:0};
        const animDef = {center:coord}; //, zoom: 1, rotation:0, duration:10};
        map.getView().animate(animDef);
    } */

    // on display panel, center map on feature
    useEffect(() => {
      if (panelVisible) {
        ZoomAndCenterSite()
      }
    }, [panelVisible, feature]);

  return (
    <Drawer
      anchor="bottom"
      variant="persistent"
      open={panelVisible}
      onClose={onClose}
      //elevation={0}
    >
      {/*}
      <center><Fab
        size="small"
        onClick={() => setPanelVisibility(false)} 
        sx={{ marginTop:"10px", marginBottom:"10px" }}
    ><ClosePanelIcon /></Fab></center> */}

      <Box>
        { renderHeader() }

        <Box sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex', height: 190 }} >
        <Tabs
            orientation="vertical"
            variant="scrollable"
            value={selectedPage}
            onChange={handleChange}
            aria-label="Vertical tabs example"
            sx={{ borderRight: 1, borderColor: 'divider' }}
        >
        <Tab label="Site info" {...a11yProps(0)} />
        <Tab label="Sea state" {...a11yProps(1)} />
        <Tab label="Weather" {...a11yProps(2)} />        
      </Tabs>  

      <TabPanel value={selectedPage} index={0}>
         { renderSiteInfo() } 
      </TabPanel>
      <TabPanel value={selectedPage} index={1}>
        Soon...
      </TabPanel>
      <TabPanel value={selectedPage} index={2}>
        Soon...
      </TabPanel>

      </Box>      
      </Box>      
    </Drawer>
  );
}

export default PanelFeatureInfo;
