import {CMEMS_createChildLayer, CMEMS_createLayer, CMEMS_createGroupLayer, CMEMS_createGroupLayerFromParams} from './CMEMSFunctions.js'


// Definition of Map background layers
// zindex : 0 = behind (ie image), 10 = intermediate, 100 = top
export default function createLayersGroupSea(categoryId="sea") {
  
    var dt_now = new Date();
    //dt_now.setDate(dt_now.getDate() - 2);
    dt_now.setUTCHours(0,0,0);
    const dateTime = dt_now.toISOString(); //'2024-05-09T00:00:00Z';
    //console.log(dateTime);  
    
    var dt_now2 = new Date();
    dt_now2.setDate(dt_now.getDate() - 1);  
    dt_now2.setUTCHours(0,0,0);
    const datetime_yest = dt_now2.toISOString(); //'2024-05-09T00:00:00Z'; console.log(dateTime);     

    console.log('***** visibility today datetime : ',dateTime);
    console.log('***** visibility yesterday datetime : ',datetime_yest);    

    // ------------------------------------------------------------------------------------------------------------
    //                                          Visibility layers
    // ------------------------------------------------------------------------------------------------------------
       
    const layerGroupKD490CMEMSGlobal = CMEMS_createGroupLayerFromParams(
      "Water visibility (today, grid)", categoryId, 10, 1,

      [ 
        {dataset:"GLOBAL_ANALYSISFORECAST_BGC_001_028/cmems_mod_glo_bgc-optics_anfc_0.25deg_P1D-m_202311", param:"kd"},
        {dataset:"MEDSEA_ANALYSISFORECAST_BGC_006_014/cmems_mod_med_bgc-optics_anfc_4.2km_P1D-m_202211", param:"kd490"}
      ],  
      
      dateTime, "cmap:dense",

      'images/water_visi_3.png',
      'Diffuse attenuation coefficient',
      'http://nrt.cmems-du.eu/thredds/wms/cmems_obs-oc_glo_bgc-transp_nrt_l4-gapfree-multi-4km_P1D?REQUEST=GetLegendGraphic&LAYER=KD490&PALETTE=rainbow',
      'https://data.marine.copernicus.eu/product/GLOBAL_ANALYSISFORECAST_BGC_001_028/description'       
     );   
     

     
    
     /*
     const layerGroupKD490CMEMSGlobalObsYest = CMEMS_createGroupLayerFromParams(
      "Water visibility (yesterday, obs)", categoryId, 10, "images/water_visi_3.png", "cmap:dense",

      [ 
        {dataset:"OCEANCOLOUR_GLO_BGC_L4_NRT_009_102/cmems_obs-oc_glo_bgc-transp_nrt_l4-gapfree-multi-4km_P1D_202311", param:"KD490"},
        {dataset:"OCEANCOLOUR_MED_BGC_L4_NRT_009_142/cmems_obs-oc_med_bgc-transp_nrt_l4-olci-300m_P1M_202207", param:"KD490"}
      ],       

      datetime_yest,

      'Diffuse attenuation coefficient',
      'http://nrt.cmems-du.eu/thredds/wms/cmems_obs-oc_glo_bgc-transp_nrt_l4-gapfree-multi-4km_P1D?REQUEST=GetLegendGraphic&LAYER=KD490&PALETTE=rainbow',
      'https://data.marine.copernicus.eu/product/OCEANCOLOUR_GLO_BGC_L4_NRT_009_102/description'

     );  */
     
     const layerGroupKD490CMEMSGlobalObsYest = CMEMS_createLayer(
      "Water visibility (yesterday, obs)", categoryId, 10, 1,

      "OCEANCOLOUR_GLO_BGC_L4_NRT_009_102/cmems_obs-oc_glo_bgc-transp_nrt_l4-gapfree-multi-4km_P1D_202311",      
      "KD490", datetime_yest, "cmap:dense", 

      "images/water_visi_3.png",
      'Diffuse attenuation coefficient',
      '',
      'https://data.marine.copernicus.eu/product/OCEANCOLOUR_GLO_BGC_L4_NRT_009_102/description'
    );  


    /*
    const layer_cmems_zsd = new TileLayer({
      title: "Secchi depth of sea water",        
      layerType: categoryId,
      visible: false,
      zIndex: 10,
      //opacity: 0.6,
      layer_icon: "images/icon_IsoDepth.png",
      source: new TileWMS({
        url: "https://nrt.cmems-du.eu/thredds/wms/cmems_obs-oc_glo_bgc-transp_nrt_l4-gapfree-multi-4km_P1D?service=WMS",
        params: { LAYERS: "ZSD", TILED: true },  
        transition: 0,
      }),
      
      legend_description: 'Depth of sea water (Secchi disk)',
      legend_url_graphic: 'https://nrt.cmems-du.eu/thredds/wms/cmems_obs-oc_glo_bgc-transp_nrt_l4-gapfree-multi-4km_P1D?REQUEST=GetLegendGraphic&LAYER=ZSD&PALETTE=rainbow',
      legend_url_info: 'https://data.marine.copernicus.eu/product/OCEANCOLOUR_GLO_BGC_L4_NRT_009_102/description',        
    });    
    
    const layerKD490CMEMSGlobal = CMEMS_createLayer(
      "Water visibility", categoryId, 10, "images/water_visi_3.png",
      
      "OCEANCOLOUR_GLO_BGC_L4_NRT_009_102/cmems_obs-oc_glo_bgc-transp_nrt_l4-gapfree-multi-4km_P1D_202311",
      //"OCEANCOLOUR_MED_BGC_L4_NRT_009_142/cmems_obs-oc_med_bgc-transp_nrt_l4-olci-300m_P1M_202207",
      "KD490", dateTime, "cmap:dense", //"cmap:rainbow", //"cmap:range:1.99/2.0", //"cmap:rainbow",

      'Diffuse attenuation coefficient',
      'http://nrt.cmems-du.eu/thredds/wms/cmems_obs-oc_glo_bgc-transp_nrt_l4-gapfree-multi-4km_P1D?REQUEST=GetLegendGraphic&LAYER=KD490&PALETTE=rainbow',
      //'https://data.marine.copernicus.eu/product/OCEANCOLOUR_GLO_BGC_L3_NRT_009_101/description',  
      'https://data.marine.copernicus.eu/product/OCEANCOLOUR_GLO_BGC_L4_NRT_009_102/description'
    ); 
   */

    // https://wmts.marine.copernicus.eu/teroWmts??service=WMTS&request=GetCapabilities

   
    // ------------------------------------------------------------------------------------------------------------
    //                                          Sea Temperature layers
    // ------------------------------------------------------------------------------------------------------------

    // others local areas (mediterean, north-west atlantic)
    // "MEDSEA_ANALYSISFORECAST_PHY_006_013/cmems_mod_med_phy-tem_anfc_4.2km_P1D-m_202311/thetao"
    // "NWSHELF_ANALYSISFORECAST_PHY_004_013/cmems_mod_nws_phy_anfc_0.027deg-3D_P1D-m_202309",

    const layerGroupTempCMEMS = CMEMS_createGroupLayerFromParams(
      "Sea Temperature", categoryId, 10, 1,

      [ 
        {dataset:"GLOBAL_ANALYSISFORECAST_PHY_001_024/cmems_mod_glo_phy-thetao_anfc_0.083deg_P1D-m_202406", param:"thetao"},
        {dataset:"MEDSEA_ANALYSISFORECAST_PHY_006_013/cmems_mod_med_phy-tem_anfc_4.2km_P1D-m_202311", param:"thetao"}
      ],  
      
      dateTime, "cmap:rainbow,range:10/30", // "cmap:rainbow,range:-2/35",

      'images/icon_layer_seatemp_90x60_3.png',
      'Sea temperature (NEMO)',
      '',
      'https://data.marine.copernicus.eu/product/GLOBAL_ANALYSISFORECAST_PHY_001_024/description'       
     );     
   
  /*
    const sublayerTempCMEMS14deg = CMEMS_createChildLayer(
      'sub Sea temp 14°C', 
      'GLOBAL_ANALYSISFORECAST_PHY_001_024/cmems_mod_glo_phy-thetao_anfc_0.083deg_P1D-m_202211', 'thetao', 
      dateTime, "cmap:ice,range:-5/14,noClamp");

      const sublayerTempCMEMS20deg = CMEMS_createChildLayer(
        'sub Sea temp 20°C', 
        'GLOBAL_ANALYSISFORECAST_PHY_001_024/cmems_mod_glo_phy-thetao_anfc_0.083deg_P1D-m_202211', 'thetao', 
        dateTime, "cmap:solar,range:14/20,noClamp");

      const sublayerTempCMEMS25deg = CMEMS_createChildLayer(
        'sub Sea temp 25°C', 
        'GLOBAL_ANALYSISFORECAST_PHY_001_024/cmems_mod_glo_phy-thetao_anfc_0.083deg_P1D-m_202211', 'thetao', 
        dateTime, "cmap:amp,range:20/25,noClamp");        

      const layerGroupTempZoneCMEMS = CMEMS_createGroupLayer(
        "Sea temperature zone", categoryId, 10, "images/icon_layer_seatemp_90x60_3.png",      
        [sublayerTempCMEMS14deg,sublayerTempCMEMS20deg,sublayerTempCMEMS25deg],

        'Sea temperature (NEMO)',
        'https://nrt.cmems-du.eu/thredds/wms/cmems_mod_glo_phy-thetao_anfc_0.083deg_PT6H-i?REQUEST=GetLegendGraphic&LAYER=thetao&PALETTE=rainbow',
        'https://data.marine.copernicus.eu/product/GLOBAL_ANALYSISFORECAST_PHY_001_024/description'
      );

    const layerTempCMEMSGlobal = CMEMS_createLayer(
      "Sea temperature", categoryId, 10, "images/icon_layer_seatemp_90x60_3.png",

      "GLOBAL_ANALYSISFORECAST_PHY_001_024/cmems_mod_glo_phy-thetao_anfc_0.083deg_P1D-m_202211",
      "thetao", dateTime, "cmap:solar,range:14/20,noClamp", // "cmap:ice,range:-5/14,noClamp",

      'Sea temperature (NEMO)',
      'https://nrt.cmems-du.eu/thredds/wms/cmems_mod_glo_phy-thetao_anfc_0.083deg_PT6H-i?REQUEST=GetLegendGraphic&LAYER=thetao&PALETTE=rainbow',
      'https://data.marine.copernicus.eu/product/GLOBAL_ANALYSISFORECAST_PHY_001_024/description'
    ); */
    
    /*  
    const layerGroupTempCMEMS = createLayerGroup_SeaTemperature('Sea Temperature', 'cmap:rainbow,range:-2/35', 'images/icon_layer_seatemp_90x60_3.png', 'Sea temperature (NEMO)');
    const layerTempCMEMS25deg = createLayerGroup_SeaTemperature('Sea temp 25°C',   'cmap:rainbow,range:24.9/25', 'images/icon_layer_seatemp14deg_3.png', 'Sea temperature lower and greater 25°C (NEMO)');
    const layerTempCMEMS20deg = createLayerGroup_SeaTemperature('Sea temp 20°C',   'cmap:rainbow,range:19.9/20',  'images/icon_layer_seatemp14deg_3.png','Sea temperature lower and greater 20°C (NEMO)');
    const layerTempCMEMS14deg = createLayerGroup_SeaTemperature('Sea temp 14°C',   'cmap:rainbow,range:13.999/14.0',  'images/icon_layer_seatemp14deg_3.png','Sea temperature lower and greater 14°C (NEMO)');
    */ 

    // ------------------------------------------------------------------------------------------------------------
    //                                          Current layers
    // ------------------------------------------------------------------------------------------------------------
    
    const layerCurrentWorld = CMEMS_createLayer(
      "Current (world)", categoryId, 10, 1, 

      //"GLOBAL_ANALYSISFORECAST_PHY_001_024/cmems_mod_glo_phy_anfc_0.083deg_PT1H-m_202406", "sea_water_velocity",
      "GLOBAL_ANALYSISFORECAST_PHY_001_024/cmems_mod_glo_phy-cur_anfc_0.083deg_P1D-m_202406", "sea_water_velocity",
      dateTime, "cmap:speed,vectorStyle:vector", // "cmap:speed,vectorStyle:solidAndVector"

      "images/icon_current_2.png",
      'Current',
      '',
      'https://data.marine.copernicus.eu/product/GLOBAL_ANALYSISFORECAST_PHY_001_024/description'
    );    

    const layerCurrentMed = CMEMS_createLayer(
      "Current (mediteranean)", categoryId, 10, 1, 

      //"MEDSEA_ANALYSISFORECAST_PHY_006_013/cmems_mod_med_phy-cur_anfc_4.2km-2D_PT1H-m_202311", "sea_water_velocity",
      "MEDSEA_ANALYSISFORECAST_PHY_006_013/cmems_mod_med_phy-cur_anfc_4.2km_P1D-m_202311","sea_water_velocity",
      dateTime, "cmap:speed,vectorStyle:vector", // "cmap:speed,vectorStyle:solidAndVector"

      "images/icon_current_2.png",
      'Current',
      '',
      'https://data.marine.copernicus.eu/product/GLOBAL_ANALYSISFORECAST_PHY_001_024/description'
    );     

    /*
   const layerCurrentCMEMSGlobal2 =  CMEMS_createGroupLayerFromParams(
    "Current (mediteranean)", categoryId, 10, "images/icon_current_2.png", dateTime, "cmap:speed,vectorStyle:vector",
    [ 
      //{dataset:"GLOBAL_ANALYSISFORECAST_PHY_001_024/cmems_mod_glo_phy_anfc_0.083deg_PT1H-m_202406",    param:"sea_water_velocity"},
      {dataset:"MEDSEA_ANALYSISFORECAST_PHY_006_013/cmems_mod_med_phy-cur_anfc_4.2km-2D_PT1H-m_202311",  param:"sea_water_velocity"},
      //{dataset:"BLKSEA_ANALYSISFORECAST_PHY_007_001/cmems_mod_blk_phy-cur_anfc_mrm-500m_P1D-m_202311", param:"sea_water_velocity"}
      // MEDSEA_ANALYSISFORECAST_PHY_006_013/cmems_mod_med_phy-cur_anfc_4.2km_PT15M-i_202311
      // MEDSEA_ANALYSISFORECAST_PHY_006_013/cmems_mod_med_phy-cur_anfc_4.2km-2D_PT1H-m_202311
      // BLKSEA_ANALYSISFORECAST_PHY_007_001/cmems_mod_blk_phy-cur_anfc_mrm-500m_PT1H-i_202311/sea_water_velocity
      // BLKSEA_ANALYSISFORECAST_PHY_007_001/cmems_mod_blk_phy-cur_anfc_mrm-500m_P1D-m_202311
      // BLKSEA_ANALYSISFORECAST_PHY_007_001/cmems_mod_blk_phy-cur_anfc_2.5km_P1D-m_202311
      // BLKSEA_ANALYSISFORECAST_PHY_007_001/cmems_mod_blk_phy-cur_anfc_detided_2.5km_P1D-m_202311
    ],       
    'Current',  '',   ''
   ); */

    
    // ------------------------------------------------------------------------------------------------------------
    //                                          Wave layers
    // ------------------------------------------------------------------------------------------------------------    
    
    const layerWaveHeightCMEMSGlobal = CMEMS_createLayer(
      "Wave height", categoryId, 10, 1,

      "GLOBAL_ANALYSISFORECAST_WAV_001_027/cmems_mod_glo_wav_anfc_0.083deg_PT3H-i_202311", "VHM0",
      //"MEDSEA_ANALYSISFORECAST_WAV_006_017/cmems_mod_med_wav_anfc_4.2km_PT1H-i_202311",
       dateTime, "cmap:rainbow", //"cmap:range:1.99/2.0", //"cmap:rainbow",

      "images/icon_layer_waveheight_90x60_2.png",
      'Wave height (MFWAM)',
      '',
      ''
    );  
  
  // ------------------------------------------------------------------------------------------------------------       



  return [    
    layerGroupKD490CMEMSGlobal,
    layerGroupKD490CMEMSGlobalObsYest,
    //layerTempCMEMSGlobal,
    //layerGroupTempZoneCMEMS,
    layerGroupTempCMEMS,
    //layerTempCMEMS25deg,
    //layerTempCMEMS20deg,
    //layerTempCMEMS14deg,

    layerWaveHeightCMEMSGlobal,     

    layerCurrentWorld,
    layerCurrentMed,
  
  ];
}
