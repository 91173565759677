import React from "react";
import Divider from '@mui/material/Divider';

import PopupWindow from "./Popup";


function PopupWindowLayerLegend({ layerInfoTitle, layerInfoContent, layerInfoURLDesc, layerInfoURLGraphic, layerInfoDateTime, ...props }) {  

  return (
    <PopupWindow title={layerInfoTitle} {...props}>
      {layerInfoDateTime && (
        <div>
          Date:{" "}
          {new Intl.DateTimeFormat("en-UK", {
            year: "numeric",
            month: "long",
            day: "numeric",
            hour: "2-digit",
            minute: '2-digit',
            hour12: false
          }).format(new Date(layerInfoDateTime))}{" "}
          <br />
          <br />
        </div>
      )}
      {layerInfoContent}
      <p>
        <a href={layerInfoURLDesc} target="_blank">
          Data source Link
        </a>
      </p>
      <Divider />
      <p>
        <div>
          <img src={layerInfoURLGraphic} style={{ marginRight: "10px" }}></img>
        </div>
      </p>
    </PopupWindow>
  );
}

export default PopupWindowLayerLegend;
